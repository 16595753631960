import React, {Component} from 'react'
import {Button, Modal, Form} from 'react-bootstrap'
import {FaTwitter} from 'react-icons/fa'
import UserIcon from '../../assets/imgs/user.png'

import {auth, signInWithTwitter} from "../../firebase/firebase.utils";

const regExp = RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

class LoginModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            isError: {
                email: '',
                password: ''
            }
        }
    }

    formValChange = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let isError = {...this.state.isError};

        switch (name) {
            case "email":
                isError.email = regExp.test(value) ? "" : "メールアドレスの形式が正しくありません";
                break;
            case "password":
                isError.password = value.length < 6 ? "最低6文字以上" : "";
                break;
            default:
                break;
        }

        this.setState({
            isError,
            [name]: value
        })
    };

    onSubmit = async e => {
        e.preventDefault();
        let isError = {...this.state.isError};
        const { email, password } = this.state;

        if(email === ""){
            isError.email = "入力してください"
        }

        if(password === ""){
            isError.password = "入力してください"
        }

        try {
            await auth.signInWithEmailAndPassword(email, password);
            this.setState({
                email: '',
                password: ''
            })
            this.props.onHide();

        } catch (error) {
            if (error.code === "auth/user-not-found") {
                isError.email = "該当するユーザー履歴がありません。ユーザー情報が削除されている可能性がございます。";
            }else if(error.code === "auth/wrong-password"){
                isError.email = "パスワードが無効か、またはパスワードを持っていないユーザーです。";
            }else{
                isError.email = "エラーが発生しました。再度お試しください。";
            }
        }

        this.setState({
            isError
        })
    };

    onSubmitTwitter = () => {
        signInWithTwitter();
        this.props.onHide();
    }

    render() {
        const {setRegisterModal, setResetPasswordModal, ...otherProps} = this.props;
        const {isError} = this.state;

        return (
            <Modal
                {...otherProps}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-register"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {/*<img className='user-icon' src={UserIcon} alt=''/>*/}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>ミスティーノにログイン</h4>
                    <Form onSubmit={this.onSubmit} noValidate>
                        <Form.Group>
                            <Form.Control type="email" name="email"
                                          defaultValue={this.state.email}
                                          onBlur={this.formValChange} placeholder="メールアドレス"
                                          className={isError.email.length > 0 ? "is-invalid form-control" : "form-control"}
                            />
                            {isError.email.length > 0 && (
                                <span className="invalid-feedback">{isError.email}</span>
                            )}
                        </Form.Group>

                        <Form.Group>
                            <Form.Control type="password" name="password"
                                          defaultValue={this.state.password}
                                          className={isError.password.length > 0 ? "is-invalid form-control" : "form-control"}
                                          onBlur={this.formValChange}
                                          placeholder="パスワード"/>
                            {isError.password.length > 0 && (
                                <span className="invalid-feedback">{isError.password}</span>
                            )}
                        </Form.Group>

                        <Form.Group className="text-center">
                            <Button variant="primary" type="submit" className="btnReg">
                                ログイン
                            </Button>
                            <Form.Text className="text-muted f-10">
                                まだアカウントをお持ちではないですか？ <a href={'#'} onClick={setRegisterModal}>今すぐ新規登録</a>
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="text-center mobile">
                            <Form.Text className="text-muted">
                                または下記の方法でログイン
                            </Form.Text>
                            <Button variant="primary" className="btn-twit"
                                    onClick={this.onSubmitTwitter}>
                                <FaTwitter/>
                            </Button>
                        </Form.Group>
                        <div className="register-remember">
                            <Form.Group>
                                <Form.Check type="checkbox" label="ログイン情報を保存する"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Text >
                                   <a href={'#'} className="forgot-pass" onClick={setResetPasswordModal}>パスワードをお忘れですか？</a>
                                </Form.Text>
                            </Form.Group>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default LoginModal


