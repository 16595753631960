import React from 'react'
import {Helmet} from "react-helmet";
import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css'


const mediaElements = [
    {
        image: require('../assets/imgs/media/august.jpg'),
        title: '2022年8月15日付:スポニチ掲載',
        credit: '(2022年8月15日付:スポニチ掲載）',
        link: require('../assets/imgs/media/august.jpg')
    },
    {
        image: require('../assets/imgs/media/july.jpg'),
        title: '2022年7月26日付:スポニチ掲載',
        credit: '(2022年7月26日付:スポニチ掲載）',
        link: require('../assets/imgs/media/july.jpg')
    },
    {
        image: require('../assets/imgs/media/20220626-1.png'),
        title: '2022年6月26日付:スポニチ掲載',
        credit: '（2022年6月26日付:スポニチ掲載）',
        link: require('../assets/imgs/media/20220626-1.png')
    },
    {
        image: require('../assets/imgs/media/20220522-1.png'),
        title: '2022年5月22日付:スポニチ掲載',
        credit: '（2022年5月22日付:スポニチ掲載）',
        link: require('../assets/imgs/media/20220522-1.png')
    },
    {
        image: require('../assets/imgs/media/20220418-1.png'),
        title: '2022年4月18日付:スポニチ掲載',
        credit: '(2022年4月18日付:スポニチ掲載)',
        link: require('../assets/imgs/media/20220418-1.png')
    },
    {
        image: require('../assets/imgs/media/20220325-1.png'),
        title: 'February Sponichi article (3月記事）',
        credit: '(2022年3月25日付:スポニチ掲載)',
        link: require('../assets/imgs/media/20220325-1.png')
    },
    {
        image: require('../assets/imgs/media/no_ads_feb_2022_thumb.jpg'),
        title: 'February Sponichi article (3月記事）',
        credit: '（2022年02月13日付: スポニチ掲載）',
        link: require('../assets/imgs/media/no_ads_feb_2022_thumb.jpg')
    },
    {
        image: require('../assets/imgs/media/no_ads_jan_2022_thumb.jpg'),
        title: 'January Sponichi article (3月記事）',
        credit: '（2022年01月1日付: スポニチ掲載）',
        link: require('../assets/imgs/media/no_ads_jan_2022_thumb.jpg')
    },
    {
        image: require('../assets/imgs/media/no_ads_dec_2021_thumb.jpg'),
        title: 'December Sponichi article (3月記事）',
        credit: '（2021年12月26日付: スポニチ掲載）',
        link: require('../assets/imgs/media/no_ads_dec_2021_thumb.jpg')
    },
    {
        image: require('../assets/imgs/media/no_ads_nov_2021_thumb.jpg'),
        title: 'November Sponichi article (3月記事）',
        credit: '（2021年11月14日付: スポニチ掲載）',
        link: require('../assets/imgs/media/no_ads_nov_2021_thumb.jpg')
    },
    {
        image: require('../assets/imgs/media/no_ads_oct_2021_thumb.jpg'),
        title: 'October Sponichi article (3月記事）',
        credit: '（2021年10月31日付: スポニチ掲載）',
        link: require('../assets/imgs/media/no_ads_oct_2021_thumb.jpg')
    },
    {
        image: require('../assets/imgs/media/no_ads_sept_2021_thumb.jpg'),
        title: 'September Sponichi article (3月記事）',
        credit: '（2021年9月26日付: スポニチ掲載）',
        link: require('../assets/imgs/media/no_ads_sept_2021_thumb.jpg')
    },
    {
        image: require('../assets/imgs/media/august-pdf.jpg'),
        title: 'August Sponichi article (3月記事）',
        credit: '（2021年8月8日付: スポニチ掲載）',
        link: require('../assets/imgs/media/august-pdf.jpg')
    },

    {
        image: require('../assets/imgs/media/july-pdf.jpg'),
        title: 'July Sponichi article (3月記事）',
        credit: '（2021年7月2日付: スポニチ掲載）',
        link: require('../assets/imgs/media/july-pdf.jpg')
    },

    {
        image: require('../assets/imgs/media/june-pdf.jpg'),
        title: 'June Sponichi article (3月記事）',
        credit: '（2021年6月18日付: スポニチ掲載）',
        link: require('../assets/imgs/media/june-pdf.jpg')
    },
    {
        image: require('../assets/imgs/media/may-pdf.jpg'),
        title: 'May Sponichi article (3月記事）',
        credit: '（2021年5月2日付: スポニチ掲載）',
        link: require('../assets/imgs/media/may-pdf.jpg')
    },

    {
        image: require('../assets/imgs/media/april-pdf.jpg'),
        title: 'April Sponichi article (3月記事）',
        credit: '（2021年4月18日付: スポニチ掲載）',
        link: require('../assets/imgs/media/april-pdf.jpg')
    },

    {
        image: require('../assets/imgs/media/march-pdf.jpg'),
        title: 'March Sponichi article (3月記事）',
        credit: '（2021年3月26日付:スポニチ掲載）',
        link: require('../assets/imgs/media/march-pdf.jpg')
    },
    {
        image: require('../assets/imgs/media/20210201.jpg'),
        title: 'February Sponichi article (2月記事）',
        credit: '（2021年2月1日付：スポニチ掲載）',
        link: require('../assets/imgs/media/20210201.jpg')
    },
    {
        image: require('../assets/imgs/media/20210101.jpg'),
        title: 'January Sponichi article (1月記事）',
        credit: '（2021年1月1日付：スポニチ掲載）',
        link: require('../assets/imgs/media/20210101.jpg')
    },
    {
        image: require('../assets/imgs/media/20201201.jpg'),
        title: 'December Sponichi article　(12月記事）',
        credit: '（2020年12月1日付：スポニチ掲載）',
        link: require('../assets/imgs/media/20201201.jpg')
    },
    {
        image: require('../assets/imgs/media/20201101.jpg'),
        title: 'November Sponichi Article (11月記事）',
        credit: '(2020年11月1日付：スポニチ掲載）',
        link: require('../assets/imgs/media/20201101.jpg')
    },
    {
        image: require('../assets/imgs/media/20201001.jpg'),
        title: 'October Sponichi article ',
        credit: '（2020年10月1日付：スポニチ掲載）',
        link: require('../assets/imgs/media/20201001.jpg')
    },
    {
        image: require('../assets/imgs/media/20200901.jpg'),
        title: 'September Sponichi article',
        credit: '（2020年9月1日付：スポニチ掲載）',
        link: require('../assets/imgs/media/20200901.jpg')
    },
    {
        image: require('../assets/imgs/media/20200803.jpg'),
        title: 'August Sponichi article',
        credit: '(2020年8月3日付：スポニチ掲載）',
        link: require('../assets/imgs/media/20200803.jpg')
    },
    {
        image: require('../assets/imgs/media/20200703.jpg'),
        title: 'July Sponichi article ',
        credit: '(2020年7月3日付：スポニチ掲載）',
        link: require('../assets/imgs/media/20200703.jpg')
    },
]

const videoElements = [
    {
        image: require('../assets/imgs/media/video-august.jpg'),
        title: '(2022年8月15日付:スポニチチャンネルYouTube掲載)',
        credit: '(2022年8月15日付:スポニチチャンネルYouTube掲載)',
        link: 'https://www.youtube.com/watch?v=s6Ype2XNuVs'
    },
    {
        image: require('../assets/imgs/media/video-july.jpg'),
        title: '(2022年7月26日付:スポニチチャンネルYouTube掲載)',
        credit: '(2022年7月26日付:スポニチチャンネルYouTube掲載)',
        link: 'https://www.youtube.com/watch?v=3A0Ps310rYU'
    },
    {
        image: require('../assets/imgs/media/june 2.png'),
        title: '(2022年6月26日付:スポニチチャンネルYouTube掲載)',
        credit: '(2022年6月26日付:スポニチチャンネルYouTube掲載)',
        link: 'https://www.youtube.com/watch?v=tKkONBm_t1Q&t=8s'
    },
 {
        image: require('../assets/imgs/media/maxresdefault (1).jpg'),
        title: '2022年5月22日付:スポニチチャンネルYouTube掲載',
        credit: '(2022年5月22日付:スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=RU3fggsn4_I'
    },
 {
        image: require('../assets/imgs/media/1645 april.png'),
        title: '2022年4月18日付:スポニチチャンネルYouTube掲載',
        credit: '(2022年4月18日付:スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=i0BptgYpyjc'
    },
 {
        image: require('../assets/imgs/media/1446 march.png'),
        title: '2022年3月25日付:スポニチチャンネルYouTube掲載',
        credit: '(2022年3月25日付:スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=r_bDWhz6KjM'
    },
 {
        image: require('../assets/imgs/media/2022-february-youtube.jpg'),
        title: 'February Sponichi Youtube （2月スポニチチャンネル）',
        credit: '(2022年2月13日付：スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=tKkONBm_t1Q&t=8s'
    },
    {
        image: require('../assets/imgs/media/2022-january-youtube.jpg'),
        title: 'January Sponichi Youtube （1月スポニチチャンネル）',
        credit: '(2022年1月1日付：スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=drENvvrPrh0'
    },
    {
        image: require('../assets/imgs/media/2021-december-youtube.jpg'),
        title: 'December Sponichi Youtube （12月スポニチチャンネル）',
        credit: '(2021年12月26日付：スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=bSjjpoiGFbs'
    },
    {
        image: require('../assets/imgs/media/2021-november-youtube.jpg'),
        title: 'November Sponichi Youtube （11月スポニチチャンネル）',
        credit: '(2021年11月14日付：スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=K6yNi7yjcjs'
    },
    {
        image: require('../assets/imgs/media/2021-october-youtube.jpg'),
        title: 'October Sponichi Youtube （10月スポニチチャンネル）',
        credit: '(2021年10月31日付：スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=6_Py1njgXlY'
    },
    {
        image: require('../assets/imgs/media/2021-september-youtube.jpg'),
        title: 'September Sponichi Youtube （9月スポニチチャンネル）',
        credit: '(2021年9月26日付：スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=WnH9-Uu--Vc'
    },
    {
        image: require('../assets/imgs/media/august-youtube.jpg'),
        title: 'August Sponichi Youtube （8月スポニチチャンネル）',
        credit: '(2021年8月8日付:スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=i7vlaKcLtfg'
    },
    {
        image: require('../assets/imgs/media/july-youtube.jpg'),
        title: 'July Sponichi Youtube （7月スポニチチャンネル）',
        credit: '(2021年7月2日付:スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=MtPf1pWZiMQ'
    },
    {
        image: require('../assets/imgs/media/june-youtube.jpg'),
        title: 'June Sponichi Youtube （6月スポニチチャンネル）',
        credit: '(2021年6月18日付:スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=Ggr0illr4Rk'
    },
    {
        image: require('../assets/imgs/media/may-youtube.jpg'),
        title: 'May Sponichi Youtube （5月スポニチチャンネル）',
        credit: '(2021年5月2日付:スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=lGBn7L9_o28'
    },

    {
        image: require('../assets/imgs/media/april-youtube.jpg'),
        title: 'April Sponichi Youtube （4月スポニチチャンネル）',
        credit: '(2021年4月18日付:スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=9ccrjkNzEU4'
    },

    {
        image: require('../assets/imgs/media/march-youtube.jpg'),
        title: 'March Sponichi Youtube （3月スポニチチャンネル）',
        credit: '(2021年3月26日付:スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=e0_wl2zETWQ&t=5s'
    },
    {
        image: require('../assets/imgs/media/february-youtube.jpg'),
        title: 'February Sponichi Youtube （2月スポニチチャンネル）',
        credit: '(2021年2月1日付:スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=WInMEy6jIEA&feature=youtu.be'
    },
    {
        image: require('../assets/imgs/media/january-youtube.jpg'),
        title: 'January Sponichi Youtube （1月スポニチチャンネル）',
        credit: '(2021年1月1日付:スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=yUgsHtDctPo&feature=youtu.be'
    },
    {
        image: require('../assets/imgs/media/december-youtube.jpg'),
        title: 'December Sponichi Youtube （12月スポニチチャンネル）',
        credit: '(2020年12月1日付:スポニチチャンネルYouTube掲載）',
        link: 'https://www.youtube.com/watch?v=JdjPW86ZV1E&t=8s'
    },
]

const articleElements = [
    {
        image: require('../assets/imgs/media/Mystino_august.png'),
        title: '2022年8月15日付:スポニチアネックス掲載',
        credit: '(2022年8月15日付:スポニチアネックス掲載）',
        link: 'https://www.sponichi.co.jp/entertainment/news/2022/08/15/kiji/20220815b00041000004000c.html'
    },
    {
        image: require('../assets/imgs/media/Mystino_july.png'),
        title: '2022年7月26日付:スポニチアネックス掲載',
        credit: '(2022年7月26日付:スポニチアネックス掲載) ',
        link: 'https://www.sponichi.co.jp/entertainment/news/2022/07/26/kiji/20220726b00041000005000c.html'
    },
    {
        image: require('../assets/imgs/media/June.png'),
        title: '2022年6月26日付:スポニチアネックス掲載',
        credit: '(2022年6月26日付:スポニチアネックス掲載）',
        link: 'https://www.sponichi.co.jp/entertainment/news/2022/06/27/kiji/20220627b00041000006000c.html'
    },
    {
        image: require('../assets/imgs/media/May.png'),
        title: '2022年5月22日付:スポニチアネックス掲載）',
        credit: '(2022年5月22日付:スポニチアネックス掲載）',
        link: 'https://www.sponichi.co.jp/entertainment/news/2022/05/23/kiji/20220523b00041000006000c.html'
    },
    {
        image: require('../assets/imgs/media/April.png'),
        title: '2022年4月18日付:スポニチアネックス掲載',
        credit: '(2022年4月18日付:スポニチアネックス掲載）',
        link: 'https://www.sponichi.co.jp/entertainment/news/2022/04/18/kiji/20220418b00041000005000c.html'
    },
    {
        image: require('../assets/imgs/media/March.png'),
        title: '2022年3月25日付:スポニチアネックス掲載',
        credit: '(2022年3月25日付:スポニチアネックス掲載）',
        link: 'https://www.sponichi.co.jp/entertainment/news/2022/03/25/kiji/20220325b00041000009000c.html'
    },
    {
        image: require('../assets/imgs/media/2022-february-article.jpg'),
        title: 'February Sponichi Annex article　（2月スポニチアネックス記事）',
        credit: '(2022年2月14日付: スポニチアネックス掲載）',
        link: 'https://www.sponichi.co.jp/entertainment/news/2022/02/14/kiji/20220214b00041000005000c.html'
    },
    {
        image: require('../assets/imgs/media/2022-january-article.jpg'),
        title: 'January Sponichi Annex article　（1月スポニチアネックス記事）',
        credit: '(2022年1月1日付: スポニチアネックス掲載）',
        link: 'https://www.sponichi.co.jp/entertainment/news/2022/01/01/kiji/20220101b00041000003000c.html'
    },
    {
        image: require('../assets/imgs/media/2021-december-article.jpg'),
        title: 'December Sponichi Annex article　（12月スポニチアネックス記事）',
        credit: '(2021年12月26日付: スポニチアネックス掲載）',
        link: 'https://www.sponichi.co.jp/entertainment/news/2021/12/26/kiji/20211226b00041000003000c.html'
    },
    {
        image: require('../assets/imgs/media/2021-november-article.jpg'),
        title: 'November Sponichi Annex article　（11月スポニチアネックス記事）',
        credit: '(2021年11月14日付: スポニチアネックス掲載）',
        link: 'https://www.sponichi.co.jp/entertainment/news/2021/11/14/kiji/20211114b00041000005000c.html'
    },
    {
        image: require('../assets/imgs/media/2021-october-article.jpg'),
        title: 'October Sponichi Annex article　（10月スポニチアネックス記事）',
        credit: '(2021年10月31日付: スポニチアネックス掲載）',
        link: 'https://www.sponichi.co.jp/entertainment/news/2021/10/31/kiji/20211031b00041000005000c.html'
    },
    {
        image: require('../assets/imgs/media/2021-september-article.jpg'),
        title: 'September Sponichi Annex article　（9月スポニチアネックス記事）',
        credit: '(2021年9月26日付: スポニチアネックス掲載）',
        link: 'https://www.sponichi.co.jp/entertainment/news/2021/09/26/kiji/20210926b00041000005000c.html'
    },
    {
        image: require('../assets/imgs/media/august-img.jpg'),
        title: 'August Sponichi Annex article　（7月スポニチアネックス記事）',
        credit: '(2021年8月8日付: スポニチアネックス掲載）',
        link: 'https://www.sponichi.co.jp/entertainment/news/2021/08/08/kiji/20210808b00041000007000c.html'
    },
    {
        image: require('../assets/imgs/media/july-img.jpg'),
        title: 'July Sponichi Annex article　（7月スポニチアネックス記事）',
        credit: '(2021年7月2日付: スポニチアネックス掲載）',
        link: 'https://www.sponichi.co.jp/entertainment/news/2021/07/23/kiji/20210723b00041000004000c.html'
    },
    {
        image: require('../assets/imgs/media/june-img.jpg'),
        title: 'June Sponichi Annex article　（6月スポニチアネックス記事）',
        credit: '(2021年6月2日付: スポニチアネックス掲載）',
        link: 'https://www.sponichi.co.jp/entertainment/news/2021/06/27/kiji/20210627b00041000005000c.html'
    },
    {
        image: require('../assets/imgs/media/may-img.jpg'),
        title: 'May Sponichi Annex article　（5月スポニチアネックス記事）',
        credit: '(2021年5月2日付: スポニチアネックス掲載）',
        link: ' https://www.sponichi.co.jp/entertainment/news/2021/05/02/kiji/20210502s00041000271000c.html'
    },

    {
        image: require('../assets/imgs/media/april-img.jpg'),
        title: 'April Sponichi Annex article　（4月スポニチアネックス記事）',
        credit: '(2021年4月18日付:スポニチアネックス掲載）',
        link: ' https://www.sponichi.co.jp/entertainment/news/2021/04/18/kiji/20210418s00041000308000c.html'
    },

    {
        image: require('../assets/imgs/media/march-img.jpg'),
        title: 'March Sponichi Annex article　（3月スポニチアネックス記事）',
        credit: '(2021年3月26日付:スポニチアネックス掲載）',
        link: ' https://www.sponichi.co.jp/entertainment/news/2021/03/26/kiji/20210326s00041000258000c.html'
    },
    {
        image: require('../assets/imgs/media/february-img.jpg'),
        title: 'February Sponichi Annex article　（2月スポニチアネックス記事）',
        credit: '(2021年2月1日付：スポニチアネックス掲載）',
        link: ' https://www.sponichi.co.jp/entertainment/news/2021/02/01/kiji/20210201s00041000267000c.html'
    },
    {
        image: require('../assets/imgs/media/january-img.jpg'),
        title: 'January Sponichi Annex article　（1月スポニチアネックス記事）',
        credit: '(2021年1月1日付：スポニチアネックス掲載）',
        link: ' https://www.sponichi.co.jp/entertainment/news/2021/01/01/kiji/20210101s00041000274000c.html'
    },
    {
        image: require('../assets/imgs/media/december-img-new.jpg'),
        title: 'December Sponichi Annex article　（12月スポニチアネックス記事）',
        credit: '(2020年12月1日付：スポニチアネックス掲載）',
        link: 'https://www.sponichi.co.jp/entertainment/news/2020/12/01/kiji/20201201s00041000170000c.html'
    },

]

const eventsElements = [
    {
        image: require('../assets/imgs/media/Poker Tournament2022.jpg'),
        title: '',
        credit: '2022年スポニチポーカー大会協賛',
        link: require('../assets/imgs/media/Poker Tournament2022.jpg')
    },
    {
        image: require('../assets/imgs/media/february-event.jpg'),
        title: '',
        credit: '2021年スポニチポーカー大会協賛',
        link: require('../assets/imgs/media/february-event.jpg')
    },
]

class MediaExposureNew extends React.Component {

    showMediaExposure = () => {
        return mediaElements.map((elem, index) => (
            <div key={index} className="col-md-4 col-sm-6">
                <div className="row mb-5">
                    <div className="col-12 text-center">
                        <a href={elem.link} target="_blank" rel="noopener noreferrer">
                            <img src={elem.image} alt='{elem.title}'  />
                        </a>
                        <a href={elem.link} target="_blank" rel="noopener noreferrer" className="text-decoration-none"><h3>{elem.credit}</h3></a>
                        {/*<p className="credit-text">{elem.credit}</p>*/}
                    </div>
                    {/*<div className="col-md-7 d-flex align-items-center justify-center-m">*/}
                    {/*    <div>*/}
                    {/*        <a href={elem.link} target="_blank" className="text-decoration-none"><h3>{elem.credit}</h3></a>*/}
                    {/*        <p className="credit-text">{elem.credit}</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        ))
    }

    showVideoExposure = () => {
        return videoElements.map((elem, index) => (
            <div key={index} className="col-md-4 col-sm-6">
                <div className="row mb-5">
                    <div className="col-12 text-center">
                        <a href={elem.link} target="_blank" rel="noopener noreferrer">
                            <img src={elem.image} alt='{elem.title}'  />
                        </a>
                        <a href={elem.link} target="_blank" rel="noopener noreferrer" className="text-decoration-none"><h3>{elem.credit}</h3></a>
                    </div>
                </div>
            </div>
        ))
    }

    showArticleExposure = () => {
        return articleElements.map((elem, index) => (
            <div key={index} className="col-md-4 col-sm-6">
                <div className="row mb-5">
                    <div className="col-12 text-center">
                        <a href={elem.link} target="_blank" rel="noopener noreferrer">
                            <img src={elem.image} alt='{elem.title}'  />
                        </a>
                        <a href={elem.link} target="_blank" rel="noopener noreferrer" className="text-decoration-none"><h3>{elem.credit}</h3></a>
                    </div>
                </div>
            </div>
        ))
    }

    showEventsExposure = () => {
        return eventsElements.map((elem, index) => (
            <div key={index} className="col-12 col-md-6">
                <div className="row mb-5">
                    <div className="col-12 text-center">
                        <a href={elem.link} target="_blank" rel="noopener noreferrer">
                            <img src={elem.image} alt='{elem.title}'  />
                        </a>
                        <a href={elem.link} target="_blank" rel="noopener noreferrer" className="text-decoration-none"><h3>{elem.credit}</h3></a>
                    </div>
                </div>
            </div>
        ))
    }


    render(){
        return (
            <div className='bg-white'>
                <div className='gameplay__container' style={{color: 'white'}}>
                    <Helmet>
                        <title>メディア掲載</title>
                    </Helmet>

                    <div className='container text-center' style={{fontSize: 24}}>
                        <h1>メディア掲載</h1>
                    </div>
                </div>
                <div className='media-exposure-container'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <h2 className="mb-50">イベント</h2>
                        </div>
                        <div className="row">
                            {this.showEventsExposure()}
                        </div>
                        <div className="row justify-content-center">
                            <h2 className="mb-50">新聞</h2>
                        </div>
                        <div className="row">
                            {this.showMediaExposure()}
                        </div>
                        <div className="row justify-content-center">
                            <h2 className="mb-50">番組</h2>
                        </div>
                        <div className="row">
                            {this.showVideoExposure()}
                        </div>
                        <div className="row justify-content-center">
                            <h2 className="mb-50">ウェブメディア</h2>
                        </div>
                        <div className="row">
                            {this.showArticleExposure()}
                        </div>
                    </div>
                </div>
                <Freshchat token='e6f4c493-8ef0-42d1-836a-0007b3afd0eb' host='https://wchat.eu.freshchat.com'
                           locale='ja' tags={['mystino-fp']} />
            </div>)
    }
}

export default MediaExposureNew
