import React, {Component} from 'react'
import {Button, Modal, Form} from 'react-bootstrap'
import {FaTwitter, FaEye, FaEyeSlash} from 'react-icons/fa'
import UserIcon from '../../assets/imgs/user.png'

import {auth, createUserProfileDocument, signInWithTwitter} from "../../firebase/firebase.utils";
import {Link} from "react-router-dom";

const regExp = RegExp(
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

const formValid = ({isError, ...rest}) => {
    let isValid = false;

    Object.values(isError).forEach(val => {
        if (val.length > 0) {
            isValid = false
        } else {
            isValid = true
        }
    });

    Object.values(rest).forEach(val => {
        if (val === null) {
            isValid = false
        } else {
            isValid = true
        }
    });

    return isValid;
};


class RegisterModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            terms: true,
            news: true,
            promo_code: '',
            showPassword: false,
            isError: {
                email: '',
                password: '',
                terms: '',
                news: '',
                terms_news: '',
                promo_code: ''
            }
        }
    }

    formValChange = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let isError = {...this.state.isError};

        switch (name) {
            case "email":
                isError.email = regExp.test(value) ? "" : "メールアドレスの形式が正しくありません";
                break;
            case "password":
                isError.password = value.length < 6 ? "最低6文字以上" : "";
                break;
            default:
                break;
        }

        this.setState({
            isError,
            [name]: value
        })
    };

    handleCheckbox = event => {
        let isError = {...this.state.isError};
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        if (value === true) {
            isError.news = '';
            this.setState({
                isError
            });
        }

        this.setState({
            [name]: value
        });
    }

    tooglePasswordVisibility = () => {
        const {showPassword} = this.state;

        this.setState({showPassword: !showPassword})
    }

    onSubmit = async e => {
        e.preventDefault();
        const {email, password, terms, news, isError, promo_code} = this.state;
        let isValid = true;

        if (email === "") {
            isError.email = "入力してください"
            isValid = false
        }

        if (password === "") {
            isError.password = "入力してください"
            isValid = false
        }

        if (terms === false || news === false) {
            isError.news = "上記に同意してください"
            isValid = false
        }

        if (!isValid) {
            this.setState({
                isError
            })
        }
        if (formValid(this.state) && isValid) {

            try {
                const {user} = await auth.createUserWithEmailAndPassword(email, password)
                await createUserProfileDocument(user, null, promo_code)

                this.state = {
                    email: '',
                    password: '',
                    terms: '',
                    isError: {
                        email: '',
                        password: '',
                        terms: '',
                    }
                }

                this.props.onHide(); // ascund modalul dupa register
                this.props.showRegisterSuccessModal();
            } catch (error) {

                if (error.code === "auth/email-already-in-use") {
                    isError.email = "既に登録されているメールアドレスです。ログインをしてください。"
                }else{
                    isError.email = "エラーが発生しました。再度お試しください。"
                }

                this.setState({
                    isError
                })
            }
        }
    };

    onSubmitTwitter = () => {
        signInWithTwitter();
        this.props.onHide();
    }

    render() {
        const {setLoginModal, show, onHide} = this.props;
        const {isError, showPassword} = this.state;


        return (
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-register"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {/*<img className='user-icon' src={UserIcon} alt=''/>*/}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>新規登録してプレイ</h4>
                    <Form onSubmit={this.onSubmit} noValidate>
                        <Form.Group>
                            <Form.Control type="email" name="email"
                                          defaultValue={this.state.email}
                                          onBlur={this.formValChange} placeholder="メールアドレス"
                                          className={isError.email.length > 0 ? "is-invalid form-control" : "form-control"}
                            />
                            {isError.email.length > 0 && (
                                <span className="invalid-feedback">{isError.email}</span>
                            )}
                        </Form.Group>
                        <Form.Group className="mt-24">
                            <i className="password-icon" onClick={this.tooglePasswordVisibility}>
                                {showPassword ? <FaEyeSlash size="25px"/> : <FaEye size="25px"/> }
                            </i>
                            <Form.Control type={showPassword ? 'text' : 'password'}
                                          name="password"
                                // defaultValue={this.state.password}
                                          className={isError.password.length > 0 ? "is-invalid form-control reg-inv" : "form-control"}
                                          onBlur={this.formValChange}
                                          placeholder="パスワード"/>

                            {isError.password.length > 0 && (
                                <span className="invalid-feedback">{isError.password}</span>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control type="text" name="promo_code"
                                          defaultValue={this.state.promo_code}
                                          onBlur={this.formValChange} placeholder="キャンペーンコード"
                                          className={isError.promo_code.length > 0 ? "is-invalid form-control" : "form-control"}
                            />
                            {isError.promo_code.length > 0 && (
                                <span className="invalid-feedback">{isError.promo_code}</span>
                            )}
                        </Form.Group>
                        <div className="register-terms">
                            <Form.Group className={isError.terms.length > 0 ? "" : "mb-0"} controlId={"terms"}>
                                <Form.Check type="checkbox"
                                            label=<div><Link to={`/terms-and-conditions`} target="_blank">利用規約</Link> の内容を確認し、これに同意します。</div>
                                            name="terms"
                                            className={isError.terms.length > 0 ? "is-invalid" : ""}
                                            onClick={this.handleCheckbox}
                                            defaultChecked={true}
                                />
                                {isError.terms.length > 0 && (
                                    <span className="invalid-feedback">{isError.terms}</span>
                                )}
                            </Form.Group>
                            <Form.Group style={{marginTop:'10px'}} className={isError.news.length > 0 ? "" : "mb-0"} controlId={"news"}>
                                <Form.Check type="checkbox"
                                            label="
今後のキャンペーンや新商品、サービスやイベント情報を、私たち又は信頼できる第三者機関より、ご提供頂いた詳細宛てにお知らせさせて頂くことがございます。このような情報の受け取りを希望しない場合はチェックを外してください。"
                                            name="news"
                                            className={isError.news.length > 0 ? "is-invalid" : ""}
                                            onClick={this.handleCheckbox}
                                            defaultChecked={true}
                                />
                                {isError.news.length > 0 && (
                                    <span className="invalid-feedback">{isError.news}</span>
                                )}
                            </Form.Group>

                        </div>
                        <Form.Group className="text-center">
                            <Button variant="primary" type="submit" className="btnReg">
                                新規登録
                            </Button>
                            <Form.Text className="text-muted f-10">
                                既にアカウントをお持ちですか？ <a href={'#'} onClick={setLoginModal}>今すぐログイン</a>
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="text-center mobile">
                            <Form.Text className="text-muted">
                                または下記の方法でログイン
                            </Form.Text>
                            <Button variant="primary" className="btn-twit"
                                    onClick={this.onSubmitTwitter}>
                                <FaTwitter/>
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default RegisterModal


