import React, { useState } from "react";

export default function HowToPlayVideoCard({
  imageName,
  description,
  videoUrl,
}) {
  const [showVideo, setShowVideo] = useState(false);

  const handleClick = () => {
    setShowVideo(true);
  };

  return (
    <>
      <div className="htp-container">
        {showVideo ? (
          <>
            <iframe
              className="htp-iframe"
              width="816"
              height="459"
              src={videoUrl}
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay"
            />
          </>
        ) : (
          <div onClick={handleClick}>
            <img
              className="htp-image"
              src={require(`../assets/imgs/howToPlay/${imageName}`)}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              fill="white"
              aria-hidden="true"
              className="css-4b375e css-y2p4sa"
            >
              <path d="M14 15.06V33a2 2 0 0 0 2.93 1.77l17.09-8.97a2 2 0 0 0 0-3.54L16.93 13.3A2 2 0 0 0 14 15.06z"></path>
            </svg>
          </div>
        )}
      </div>
      <p className="htp-card-description">{description}</p>
    </>
  );
}
