import React from 'react'

const GameProducer = ({ image, title, link }) => (
  <div className='gameproducer__container'>
    <div className='gameproducer__box--image'>
      <img src={image} alt='' />
    </div>
    {/*<div className='gameproducer__box--title'>*/}
    {/*  <p>{title}</p>*/}
    {/*</div>*/}
  </div>
)

export default GameProducer
