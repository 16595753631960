const howToPlayFirstColumn = [
  {
    id: 1,
    imageName: "thumbnail_1.png",
    videoUrl: "https://www.youtube.com/embed/Olm7YaP1Zbo?autoplay=1",
    description:
      "▲ スロットゲームの覚えておきたい用語説明や、さまざまなタイプのスロットゲームの遊び方を解説しています。",
  },
  {
    id: 2,
    imageName: "thumbnail_2.png",
    videoUrl: "https://www.youtube.com/embed/j2dGAXNb8qA?autoplay=1",
    description:
      "▲ パチスロ風スロットの代表作！配当の見方やルールを詳しくご紹介します。",
  },
  {
    id: 3,
    imageName: "thumbnail_3.png",
    videoUrl: "https://www.youtube.com/embed/WajfjkNB0-o?autoplay=1",
    description:
      "▲ かわいいキャラクターがモチーフの落ちもの系スロット！パズルゲームのようで直感的にわかりやすく人気のゲームです。",
  },
  {
    id: 4,
    imageName: "thumbnail_4.png",
    videoUrl: "https://www.youtube.com/embed/1y6SnXLrHC4?autoplay=1",
    description:
      "▲ 爆発力のあるフリースピンや多彩なフィーチャーで人気のゲーム。遊び方を詳しく解説しています。",
  },
  {
    id: 5,
    imageName: "thumbnail_5.png",
    videoUrl: "https://www.youtube.com/embed/DvQWn_shCFA?autoplay=1",
    description:
      "▲ メガウェイズ機能という、膨大な数のペイラインを持つスロットゲームをご紹介します。",
  },
];

const howToPlaySecondColumn = [
  {
    id: 6,
    imageName: "thumbnail_6.png",
    videoUrl: "https://www.youtube.com/embed/xYSzHUtvnQ0?autoplay=1",
    description:
      "▲ 初心者必見！ライブカジノで遊ぶ前に見ておきたい基本情報をご紹介します。",
  },
  {
    id: 7,
    imageName: "thumbnail_7.png",
    videoUrl: "https://www.youtube.com/embed/YWBXy9bItnE?autoplay=1",
    description:
      "▲ 数字が9に近い手札がどちらかを予想する、シンプルで奥が深い人気のテーブルゲームです。",
  },
  {
    id: 8,
    imageName: "thumbnail_8.png",
    videoUrl: "https://www.youtube.com/embed/rMH05_dgGzg?autoplay=1",
    description: "▲ 自分の手札をできるだけ21に近づけるカードゲームです。",
  },
  {
    id: 9,
    imageName: "thumbnail_9.png",
    videoUrl: "https://www.youtube.com/embed/qdUVmqE0bBc?autoplay=1",
    description:
      "▲ ボールがどこの数字に落ちるかを予想するゲーム。多様な賭け方ができるのが特徴です！",
  },
  {
    id: 10,
    imageName: "thumbnail_10.png",
    videoUrl: "https://www.youtube.com/embed/0jSAGHBMTRw?autoplay=1",
    description: "▲ 5枚の手札の組み合わせで役の強さを競うゲームです。",
  },
  {
    id: 11,
    imageName: "thumbnail_11.png",
    videoUrl: "https://www.youtube.com/embed/qZDUpz9VW9E?autoplay=1",
    description:
      "▲ TVショーのように司会者がゲームを進めていく、エンタメ性あふれるライブゲーム！",
  },
];

const dataHowToPlay = {
  howToPlayFirstColumn,
  howToPlaySecondColumn,
};

export default dataHowToPlay;
