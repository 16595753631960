import React, { Component } from "react";
import "./assets/scss/main.scss";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  HashRouter,
  useLocation
} from "react-router-dom";
import NewNavbar from "./layout/NewNavbar";
import Navbar from "./layout/Navbar";
import Footer from "./layout/Footer";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicyEn from "./pages/PrivacyPolicyEn";
import PrivacyPolicyJa from "./pages/PrivacyPolicyJa";
import NotFound from "./pages/NotFound";
import Gamepage from "./pages/Gamepage";
import CustomModal from "./components/modals/CustomModal";
// import TestPage from "./pages/TestPage";
// import TestPageTwo from "./pages/TestPage2";

import { auth, createUserProfileDocument } from "./firebase/firebase.utils";
// import MediaExposure from "./pages/MediaExposure";
// import NewHomepage from "./pages/NewHomepage";
import Domains from "./pages/Domains";
// import NewHomepageLP from "./pages/NewHomepageLP";
import MediaExposureNew from "./pages/MediaExposureNew";
import ResponsibleGaming from "./pages/ResponsibleGaming";
import HowToPlay from "./pages/HowToPlay";
import HomepageLP from "./pages/HomePageLP";
import {useLayoutEffect} from 'react';


const WrapperScrollToTop = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}
class App extends Component {
  unsubscribeFromAuth = null;

  constructor() {
    super();

    this.state = {
      showCustomModal: false,
      modalActive: "login-modal",
      currentUser: null,
      modalUrl: "",
    };
  }

  componentDidMount() {
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      // este open subscription
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);

        await userRef.onSnapshot((snapshot) => {
          this.setState({
            currentUser: {
              id: snapshot.id,
              ...snapshot.data(),
            },
          });

          //daca se face login cu twitter fortam completarea cu adresa de email in localStorage
          if (snapshot.data().email == null) {
            localStorage["user"] = JSON.stringify({
              id: snapshot.id,
              email: localStorage.getItem("email"),
            });
          } else {
            localStorage["user"] = JSON.stringify({
              id: snapshot.id,
              ...snapshot.data(),
            });
          }

          localStorage["isReturnVisitor"] = true;
          localStorage.removeItem("email");
        });
      } else {
        this.setState({ currentUser: userAuth }); // userAuth e null
        localStorage.removeItem("user");
        localStorage.removeItem("email");
      }
    });

    setTimeout(
      function () {
        if (this.state.currentUser == null) {
          this.showInfoModal();
        }
      }.bind(this),
      180000
    );
  }

  componentWillUnmount() {
    // inchide subscription si sesiunea
    this.unsubscribeFromAuth();
  }

  closeModal = () => {
    this.setState({ showCustomModal: false });
  };

  showLoginModal = () => {
    this.setState({
      showCustomModal: true,
      modalActive: "login-modal",
      // modalActive: 'register-success'
    });
  };

  showRegisterModal = () => {
    this.setState({
      showCustomModal: true,
      modalActive: "register-modal",
    });
  };

  showIframeModal = (url) => {
    this.setState({
      showCustomModal: true,
      modalActive: "iframe-modal",
      modalUrl: url,
    });
  };

  showInfoModal = () => {
    if (this.state.showCustomModal === false) {
      //afisare pop-up info doar daca nu este deja afisat login/register
      this.setState({
        showCustomModal: true,
        modalActive: "info-modal",
      });
    }
  };

  showRegisterSuccessModal = () => {
    this.setState({
      showCustomModal: true,
      modalActive: "register-success",
    });
  };

  showResetPasswordModal = () => {
    this.setState({
      showCustomModal: true,
      modalActive: "reset-password",
    });
  };

  signOut = () => {
    auth.signOut();
    localStorage.removeItem("twitter");
  };

  render() {
    let registerButton, loginButton;

    if (localStorage["isReturnVisitor"]) {
      registerButton = (
        <a
          className="button-login btn-log-active"
          onClick={this.showRegisterModal}
        >
          新規登録
        </a>
      );
      loginButton = (
        <a className="button-login btn-log" onClick={this.showLoginModal}>
          ログイン
        </a>
      );
    } else {
      registerButton = (
        <a className="button-register" onClick={this.showRegisterModal}>
          新規登録
        </a>
      );
      loginButton = (
        <a className="button-login btn-log" onClick={this.showLoginModal}>
          ログイン
        </a>
      );
    }

    return (
      <Router>
         <WrapperScrollToTop>
        {/*{<NewNavbar*/}
        {/*  currentUser={this.state.currentUser}*/}
        {/*  registerButton={registerButton}*/}
        {/*  loginButton={loginButton}*/}
        {/*  signOut={this.signOut}*/}
        {/*/> }*/}
        <Navbar
          currentUser={this.state.currentUser}
          registerButton={registerButton}
          loginButton={loginButton}
          signOut={this.signOut}
        />
        <CustomModal
          show={this.state.showCustomModal}
          modalActive={this.state.modalActive}
          onHide={this.closeModal}
          showRegisterModal={this.showRegisterModal}
          showLoginModal={this.showLoginModal}
          showInfoModal={this.showInfoModal}
          showRegisterSuccessModal={this.showRegisterSuccessModal}
          showResetPasswordModal={this.showResetPasswordModal}
          modalUrl={this.state.modalUrl}
        />

        <Switch>
          { <Route
            exact
            path="/"
            render={(props) => (
              <HomepageLP
                {...props}
                currentUser={this.state.currentUser}
                showModal={this.showRegisterModal}
                showIframeModal={this.showIframeModal}
                showLoginModal={this.showLoginModal}
              />
            )}
          /> }
          {/*<Route*/}
          {/*  exact*/}
          {/*  path="/hp-new"*/}
          {/*  render={(props) => (*/}
          {/*    <HomepageLP*/}
          {/*      {...props}*/}
          {/*      currentUser={this.state.currentUser}*/}
          {/*      showModal={this.showRegisterModal}*/}
          {/*      showIframeModal={this.showIframeModal}*/}
          {/*      showLoginModal={this.showLoginModal}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*/>*/}
          <Route
            exact
            path="/game/:id"
            render={(props) => (
              <Gamepage {...props} showLoginModal={this.showLoginModal} />
            )}
          />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsConditions}
          />
          <Route exact path="/privacy-policy-en" component={PrivacyPolicyEn} />
          <Route exact path="/privacy-policy-ja" component={PrivacyPolicyJa} />

          <Route
            exact
            path="/responsible-gaming"
            component={ResponsibleGaming}
          />
          <Route exact path="/how-to-play" component={HowToPlay} />

          <Route exact path="/media-exposure" component={MediaExposureNew} />
          {/*<Route exact path='/media-exposure-new' component={MediaExposureNew}/>*/}
          <Route exact path="/domains" component={Domains} />
          {/*<Route exact path='/lp'*/}
          {/*       render={(props) => <NewHomepageLP {...props} currentUser={this.state.currentUser}*/}
          {/*                                         showModal={this.showRegisterModal}*/}
          {/*                                         showIframeModal={this.showIframeModal}*/}
          {/*                                         showLoginModal={this.showLoginModal}/>}*/}
          {/*/>*/}
          <Route component={NotFound} />
        </Switch>

        <Footer />
           </WrapperScrollToTop>
      </Router>
    );
  }
}

export default App;


