import React from "react";
import HowToPlayVideoCard from "./HowToPlayVideoCard";

export default function HowToPlayVideos({ title, bigDescription, data }) {
  return (
    <div>
      <h2 className="htp-subtitle">{title}</h2>
      <p className="htp-paragraph htp-paragraph-card">{bigDescription}</p>

      {data.map((item) => (
        <HowToPlayVideoCard
          key={item.id}
          imageName={item.imageName}
          description={item.description}
          videoUrl={item.videoUrl}
        />
      ))}
    </div>
  );
}
