import React from 'react'
import {Helmet} from 'react-helmet'
import BackButton from "../components/BackButton";
import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css'


const PrivacyPolicyJa = () => (
    <div className="mb-5 terms-and-conditions">
        <Helmet>
            <title>Mystino.jp プライバシーポリシー</title>
        </Helmet>

        <div className='gameplay__container' style={{color: 'white'}}>
            <div className='container text-center' style={{fontSize: 24}}>
                <h1>Mystino.jp プライバシーポリシー</h1>
            </div>
        </div>

        <div className="container">
            <div className="row mb-5">
                <div className="col-lg-12 text-left ml-2">
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>Alcanada Media Partners
                        Limited（以下「当社」）は、お客様から当社に提供された、または当社が収集したお客様に関する個人情報（以下「個人情報」）のプライバシーを保護に取り組んでいます。個人情報とは、お客様を特定する情報、またはお客様に関係する情報であり、氏名、住所、生年月日、およびお客様について保持しているその他の情報が含まれます。
                        当社は、お客様の個人情報に関する管理者（データコントローラーとも呼ばれます）となります。コントローラー(管理者)は法的な用語であり、お客様の個人情報が処理される目的の決定に責任を負うことを意味します。</p>
                    <p>当社は、お客様の個人情報を、当社が運営する国のデータ保護法、および規制に準拠して確実に処理するものとします。
                        したがって、当社はお客様の個人情報をどのように使用するかについて、このプライバシーポリシーに定めています。</p>
                    <p>当社は、このプライバシーポリシーを随時更新する可能性がありますので、このプライバシーポリシーを定期的に確認することを推奨します。
                        このプライバシーポリシーに重大な変更を加えた場合には、変更が加えられたことをお知らせします。</p>
                    <p>&nbsp;</p>
                    <p><strong>当ウェブサイトによるお客様の個人情報の収集方法</strong></p>
                    <p>当社がウェブサイトおよびモバイルアプリケーションを運営することができるよう、お客様は当社にアカウントを登録し開設する目的のため、個人情報を提供するよう求められます。
                        お客様は、当社に個人情報を提供する義務はありません。
                        ただし、当社がお客様にサービスを提供するため（例えば、お客様がウェブサイト上でゲームをプレイできるようにするため）には、特定の個人情報が必要不可欠であり、これらのサービス提供のために当社が要求した情報を提供しないことを選択した場合、特定のサービスを取得できないことがあります。当社は、当社または当社にそのような目的で従事している企業が実施する調査を通じて、個人情報を収集する場合があります。
                        さらに当社は、お客様による当社のウェブサイト、モバイルアプリケーションおよびサービスの使用に関する情報を収集します。また、このプライバシーポリシーに記載されている目的のために、お客様の個人情報の処理に必要なその他の情報も収集します。</p>
                    <p>お客様が当社のウェブサイトの公開領域にお客様の氏名、住所またはその他の個人情報を提供した場合は、この個人情報も処理します。</p>
                    <p>また、第三者サービスの提供者から個人情報を収集することもあります。</p>
                    <p>最後に、当社は個人情報を構成する端末のIPアドレスなど、特定の情報を収集する「クッキー(Cookie)」も使用します。
                        「クッキー」に関するさらなる情報は、下記のクッキーセクションをご参照くださいませ。</p>
                    <p><strong>お客様の個人情報の使用方法</strong></p>
                    <p>データ保護に関する規制に従い、当社は正当な理由がある場合のみに、お客様の個人情報を処理します。 お客様の個人情報取り扱いに関する正当な理由とは以下の通りです。
                        （i）お客様との契約の履行の下で、お客様にサービスを提供する必要がある場合。（ⅱ）法的または規制上の義務に従い、個人情報を処理する必要がある場合。（iii）個人情報を処理することが、公共の利益にあたる場合。（iv）お客様の同意を得た場合。（v）お客様の権利、自由および利益を害するものではないことを前提に、お客様の個人情報を処理することが当社の正当な利益となる場合。</p>
                    <p>以下に、当社がお客様の個人情報を処理する目的と、処理を行うための正当な利益のリストを示します。</p>
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <p><strong>目的</strong></p>
                            </td>
                            <td>
                                <p><strong>正当な利益</strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>アカウントの設定、管理、運営</p>
                            </td>
                            <td>
                                <p>契約の履行に必要</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>当社のサービスを提供するため（当社のゲームをプレイできるようにすることを含む）</p>
                            </td>
                            <td>
                                <p>契約の履行に必要</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>お客様とのコミュニケーション、およびお問い合わせの受信・返答を行うため</p>
                            </td>
                            <td>
                                <p>コミュニケーションが当社のサービスに具体的に関連する場合、契約の履行に必要</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>当社のウェブサイト、モバイルアプリケーションおよびサービスの更新について通知するため</p>
                            </td>
                            <td>
                                <p>契約の履行に必要、または正当な利益となる場合</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>お客様から提供された情報の正確性の確認、お客様の当社のサービスの使用に関する当社の規制義務を、当社が確実に満たすため</p>
                            </td>
                            <td>
                                <p>法的または規制上の義務を遵守するために必要</p>
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p>不法行為を防止または検知するため。また、不正行為、過失またはその他の深刻な不法行為から公衆を保護するため</p>
                            </td>
                            <td>
                                <p>法的または規制上の義務を遵守するために必要、または実質的な公共の利益のために必要</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>お客様が最も楽しめる製品やサービスを、よりよく理解するための市場調査を実施するため</p>
                            </td>
                            <td>
                                <p>正当な利益となる場合</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>当社の製品やサービスの改善とお客様に最適なオファーやサポートを提供するため、お客様やその他のプレイヤーが当社のウェブサイト、モバイルアプリケーションおよびサービスをどのように使用し、プロモーションや広告に反応しているかを分析し、当該するパターンやビジネスの動向を特定するため</p>
                            </td>
                            <td>
                                <p>正当な利益となる場合</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>お客様の個人プロフィールを構築し、製品やサービスに対するお客様の趣向をより深く理解するため。関連するゲームやその他ゲームサイトをお勧めするため</p>
                            </td>
                            <td>
                                <p>正当な利益となる場合</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>当社のサービスに関連するオファーやキャンペーンの情報を通知するため</p>
                            </td>
                            <td>
                                <p>同意の上</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>お客様の同意を得ていることを前提に、当社のサービスまたはその他当グループのサービスに関するオファーや、キャンペーンの情報を通知するため（詳しくは下記の「マーケティング」のセクションでご覧いただけます）</p>
                            </td>
                            <td>
                                <p>同意の上</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>ソーシャルメディアを通して広告を提供するため（ソーシャルメディアアカウントを介してのそのような広告の受信を希望していない場合を除く）</p>
                            </td>
                            <td>
                                <p>正当な利益となる場合</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>当社の契約上の義務、またはお客様が個人情報を提供した時点で、具体的に記載されていた義務の履行に必要な、その他すべての目的を支持するため</p>
                            </td>
                            <td>
                                <p>契約の履行に必要</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>カスタマーサービス担当者との通話の録音およびライブチャットを記録し、カスタマーサービス向上のトレーニングを行うため。また、セキュリティとお客様確認のため</p>
                            </td>
                            <td>
                                <p>カスタマーサポートのトレーニング：正当な利益となる場合、セキュリティとお客様確認：法的または規制上の義務を遵守するために必要</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>お客様からの要請に従って、お客様が当社のウェブサイト、モバイルアプリケーション、およびサービスを使用することを防ぐため</p>
                            </td>
                            <td>
                                <p>法的または規制上の義務を遵守するために必要</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>お客様の同意のもと、お客様の氏名、画像、ユーザー名、または所在地を使用して、宣伝やマーケティングを行うため</p>
                            </td>
                            <td>
                                <p>正当な利益となる場合</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>クッキー(Cookies)：当社のウェブサイトやモバイルアプリケーションの操作に必要なもの（ウェブサイトやモバイルアプリケーションとのやり取り、ページ間を移動する際の選択の呼び出しなど）</p>
                            </td>
                            <td>
                                <p>契約の履行に必要</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>クッキー(Cookies)：当社のウェブサイト、およびサービスの継続的な向上のため、お客様の当社ウェブサイトの使用状況を分析し、ウェブサイト利用者をモニタリングするもの。およびお客様の使用状況に合わせて、お客様により適したウェブサイトにカスタマイズするもの</p>
                            </td>
                            <td>
                                <p>正当な利益となる場合</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>クッキー(Cookies)：お客様の当社ウェブサイトへの往来方法を理解し商業的な取り決めを効果的にするために、お客様の当社サイト間の動きを追うもの</p>
                            </td>
                            <td>
                                <p>正当な利益となる場合</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>クッキー(Cookies)：複数のアカウントや不正ログイン、潜在的な詐欺の識別を含む、法令遵守に必要なもの</p>
                            </td>
                            <td>
                                <p>法的または規制上の義務を遵守するために必要</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>クッキー(Cookies)：第三者のマーケティングに使用されるもの</p>
                            </td>
                            <td>
                                <p>正当な利益となる場合</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p><strong>個人情報の開示</strong></p>
                    <p>当社に代わってサービスを提供するために、お客様の個人情報を処理代行業者へ開示する場合があります。
                        そのような処理代行業者が行うのは、このプライバシーポリシーに従ってお客様の個人情報を処理するのみで、当社はお客様の個人情報が安全に保たれるように、当該する処理代行業者と契約を締結するものとします。</p>
                    <p>当社は下記のような場合にも、お客様の個人情報を開示することがあります。</p>
                    <ul>
                        <li>政府機関、規制当局または執行機関に適用される法律または規制により要求がある場合<br/> &bull; 法的、または/および法的手続きに関連して当社を擁護するため<br/> &bull;当社がウェブサイトの運営を第三者のサービスプロバイダーに委託することを選択した場合、および/または<br/> &bull;企業取得、売却、買収、合併、資金調達、投資、組織再編、当社の事業または資産の全部または一部の売却、譲渡、売却、開示、およびそれに従うその他の手続についての交渉、および同遂行のため
                        </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p><strong>個人情報の転送</strong></p>
                    <p>お客様の個人情報は欧州連合内、およびアジア内の第三者のデータ処理機関に転送されます。当社は、このプライバシーポリシーに従い、お客様の個人情報を安全に保つための十分な対策が講じられるよう、合理的な措置を取るものとします。</p>
                    <p>&nbsp;</p>
                    <p><strong>セキュリティ</strong></p>
                    <p>当社は、お客様の個人情報を紛失、操作、不正アクセスから守るための広範な技術的および組織的措置を講じています。セキュリティ対策には、デジタルおよび物理的な周辺保護、資産管理、アクセス制御、および運用セキュリティ制御が含まれますが、これらに限定されません。
                        当社は、お客様の個人情報を常に安全に保つためにあらゆる妥当な努力を怠りませんが、お客様はセキュリティが完全に保証されないことに留意し、当社の過失によりお客様のセキュリティが損なわれた場合を除いては、当社に責任を問わないものとします。</p>
                    <p>&nbsp;</p>
                    <p><strong>マーケティング</strong></p>
                    <p>当社は、確実なマーケティングサービスを提供するために、第三者のサービスプロバイダーのサービスを使用する場合があります。
                        当社は、これらの当事者がお客様の個人情報を処理する際、厳密に当社の指示に従うよう徹底することにより、これらの当事者との取り決めがお客様のプライバシーを保護するよう措置を講じています。</p>
                    <p>【当社ウェブサイトからのマーケティングコミュニケーション】</p>
                    <p>当社ウェブサイトのユーザーは、当社からのマーケティング目的のコミュニケーションを受け取るか否か、また受け取る場合にはその受取方法を選択することができます。</p>
                    <p>当社は、そのお客様に合ったオファー提供のため、お客様の個人情報をウェブサイト上で利用することがあります。当社は、クッキーを使ってお客様のご利用状況やオンラインに対する嗜好を把握し、お客様ひとりひとりに合ったコンテンツを配信することが時折あります。クッキーがどのように使用されているかについては、上記の表および下記の「クッキー」説明欄をご覧ください。</p>
                    <p>ウェブサイトに関するマーケティング通信には以下の情報が含まれることがあります：</p>
                    <ul>
                        <li>お客様にご利用いただける当社ウェブサイトでの新作ゲームやキャンペーン情報</li>
                        <li>当社ウェブサイトのその他キャンペーン情報</li>
                    </ul>
                    <p>お客様は、当社ウェブサイトからのマーケティングコミュニケーションの受取拒否をいつでも選択することができます。</p>
                    <p>メール、SMSおよび電話によるマーケティングコミュニケーションの受取停止を希望した場合、コミュニケーションが停止されます。</p>
                    <p>&nbsp;</p>
                    <p><strong>クッキー</strong><strong>(Cookies)</strong></p>
                    <p>クッキーについて：このプライバシーポリシーでは、情報（ウェブビーコンなど）を保存するためのクッキーおよび類似の技術を表すために、「クッキー」という表現を使用しています。
                        クッキーは、ウェブサイトのサーバーによって、コンピュータまたはモバイル（またはその他）デバイスに保存される単純なテキストファイルで、そのサーバーのみがそのクッキーの内容を取得または読み取ることができます。
                        それぞれのクッキーはユーザーのウェブブラウザに固有のものです。 クッキーには、特有の識別子やウェブサイト名、数字などの匿名の情報が含まれています。</p>
                    <p>当社のウェブサイトを含む、ほとんどのウェブサイトやアプリケーションは、訪問者のユーザー体験向上のため、クッキーを利用して、ユーザーが訪問中の間（セッションクッキー）または再訪問の際に（永続的クッキー）ウェブサイトやアプリケーションがユーザーを「記憶」できるようになっています。</p>
                    <p>クッキーは、ページ間を効率的に移動したり、設定を保存したり、ウェブサイトの経験を向上させるなど、ウェブサイトやアプリケーションの使用を改善するさまざまな役割を持っています。
                        クッキーはユーザーとウェブサイトとのやりとりをより速く簡単にします。
                        ウェブサイトやアプリケーションがクッキーを使用していない場合、ユーザーがウェブサイト上のほかのページに移動するたびに、新しい訪問者とみなされます。</p>
                    <p>当社のウェブサイトと、モバイルまたはデバイス固有のバージョンおよび当ウェブサイトの関連アプリケーション（「クッキー」のセクションでは「ウェブサイト」と総称します）を含む一部のウェブサイトでは、クッキーを使用して、例えばユーザーの所在地や閲覧習慣に基づき、広告やマーケティングのメッセージをターゲットにすることもできます
                        。</p>
                    <p>クッキーには、ユーザーが閲覧しているウェブサイトによって設定されているファーストパーティ・クッキーと、閲覧しているページまたはアプリケーションにコンテンツを掲載している、ほかのウェブサイトにより設定されるサードパーティ・クッキーがあります。</p>
                    <p>当社のクッキー：当社では、ウェブサイトの利用経験を向上させるため、各ユーザーを認識し、活動を追跡するためのいくつかの異なるクッキーを使用しています。</p>
                    <p>さらに当社では、当社で提供しているサービスを遂行するために、当社のウェブサイトでクッキーを設定している多数の第三者サービスプロバイダーを使用しています。これらのサービスには、ウェブサイトの活動を追跡や、ウェブサイトの有効性を測定、マーケティングキャンペーンの有効性を評価することにより、ウェブサイトの使用と経験を向上させるための支援が含まれますが、これに限定されません。</p>
                    <p>当社および/または第三者は、ウェブサイトから情報を収集または受け取るためにクッキーを使用し、その情報を使って、他のウェブサイトに広告を掲載することがあります。
                        ユーザーは、以下のサイトより、そのような目的のために使用されたクッキーをこのウェブサイトで閲覧し、ターゲットを絞った広告のためのユーザーの情報の収集と使用から除外することができます:&nbsp;
                        <a href="https://www.aboutads.info/choices">www.aboutads.info/choices</a></p>
                    <p>クッキーの設定を行いたくない場合の対処：ユーザーの中には、コンピュータやモバイル（または他の）デバイスの情報をウェブサイトが保管するという事実、特に、これらの情報がユーザーの知らない間に第三者によって保管、さらには使用されたりすることを侵入的と感じる方もいらっしゃいます。これは一般的には無害ですが、たとえば、ユーザーの趣味をターゲットとした広告を見たいとは限りません。
                        必要に応じて、一部またはすべてのクッキーをブロックしたり、すでに設定されているクッキーを削除したりすることもできますが、その場合、そのウェブサイトの機能が失われる可能性があることに留意する必要があります。
                        ほとんどのオンラインブラウザは自動的にクッキーを受け入れるようになっていますが、ブラウザの設定を変更することで、クッキーを制限またはブロックすることができます。</p>
                    <p>&nbsp;</p>
                    <p><strong>個人情報の保存</strong></p>
                    <p>当社は、当社のサービスを提供し、法的および規制上の責任を遵守するために必要な期間、お客様の個人情報を保持します。
                        したがって、お客様の個人情報は、お客様のアカウントが閉鎖後（該当する場合）、またはお客様から最後に連絡を受けた後、最低5年間は保存されます。
                        お客様の個人情報を維持する必要がなくなった場合には、それよりも早く削除される場合もあります。お客様より、自己規制などの利用により当社のウェブサイト、モバイルアプリケーション、およびサービスにアクセスできないようにする要請があった場合は、この情報は最低7年間は保存されます。</p>
                    <p>&nbsp;</p>
                    <p><strong>個人情報の更新</strong></p>
                    <p>お客様の個人情報は、お客様のアカウントからいつでも更新することが可能です。お客様の個人情報に変更があった場合には、できるだけ速やかにアカウントを更新していただくようお願いいたします。</p>
                    <p>&nbsp;</p>
                    <p><strong>お客様の権利</strong></p>
                    <p>お客様は、お客様の個人情報に関して以下の権利を有します：</p>
                    <ul>
                        <li>当社が保有するお客様の個人情報へのアクセス権（個人情報の開示請求とも呼ばれます）<br/> &bull; 特定の個人情報を機械可読形式で受け取る権利<br/> &bull; 不正確な個人情報を訂正する権利<br/> &bull; 当社が、お客様の個人情報を処理・維持するための同意を具体的に求めており、それを処理するための法的根拠が他にない場合、お客様がこの同意を取り消す権利<br/> &bull; 処理・維持する必要がなくなった箇所、上記の段落に従って同意を取り消した箇所、下の段落に従って異議を唱えた箇所、お客様の個人情報が不法に処理された箇所、
                            法的義務に従ってお客様の個人情報を消去する必要がある場合といった特定の個人情報を消去する権利<br/> &bull; 当社の正当な利益という法的根拠に基づいた処理に異議を申し立てる権利、ただし、その他の関係した法的根拠がある場合、またはユーザーの権利、利益、または自由によって覆すことのできないため、個人情報の処理継続にやむを得ない理由がある場合は、お客様の個人情報の処理は継続されます。<br/> &bull; 自動化された手段のみを通じて、お客様に関する決断が行われた場合のそのロジックの説明を求める権利<br/> &bull; ユーザーが在住する国のデータ保護機関に不平を言う権利<br/> &bull; お客様のアカウント、または通信を通して受取拒否の設定をすることが可能なダイレクトマーケティングに反対する権利。
                            また、ダイレクトマーケティングに関連する限りのプロファイリングに異議を申し立てる権利も有します。<br/></li>
                    </ul>
                    <p>お客様の権利に不明点がある場合や、個人情報の処理方法についてが懸念がある場合は、お客様が在住する国のデータ保護機関に連絡することをお勧めします。</p>
                    <p>お客様の権利を行使されたい場合は、下記の方法で当社にご連絡ください。 当社は、お客様の権利に関する要請にできる限り対応するよう努めておりますが、これらは必ずしも絶対的な権利ではありません。
                        これは、お客様のリクエストを拒否しなければならない場合や、要請の一部に応じられない場合があることを意味します。</p>
                    <p>お客様より、お客様の権利に関する要求があった場合、お客様の身分証明の提出が必要となります。 また、お客様のご要望を明確にするようお願いする場合があります。
                        当社は、お客様の身元を確認してから1ヶ月以内に、いかなる要請にも対応するよう努めるものとします。
                        繰り返しの要求を受けた場合、または要求が不当になされたと思われる理由がある場合、当社は返答しない権利を留保します。</p>
                    <p>【連絡先】</p>
                    <p>個人情報の使用に関するご質問がある場合や、権利を行使されたい場合は、dataprotection@mystino.jpまでご連絡ください。</p>
                    <BackButton/>
                </div>
            </div>
        </div>
        <Freshchat token='e6f4c493-8ef0-42d1-836a-0007b3afd0eb' host='https://wchat.eu.freshchat.com'
                   locale='ja' tags={['mystino-fp']} />
    </div>
)

export default PrivacyPolicyJa
