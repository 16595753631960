import {Helmet} from "react-helmet";
import React from "react";
import {Link} from "react-router-dom";
import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css'


export default class Domains extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (<div className='container-fluid' style={{backgroundColor:'#FBFBFA', padding:0}}>
            <Helmet>
                <title>ミスティーノFREE | 無料オンラインカジノゲーム</title>
            </Helmet>
            <div className="pink__band do"><Link to={'/domains'}>.jp </Link>&nbsp;なら、リアルマネーが一切かかりません！<Link to={'/domains'}>ドメインについて </Link></div>
            <div className="container domains">
                <div className="domains__title">ドメインについて</div>
                <div className="domains__subtitle">最もよく使われる6つのドメインについて説明します！</div>
                <div className="domain__box pink__box">
                    <span>1</span>
                    <span className="white-do">.com</span>
                    <p>世界中で最も登録数が多く、商用サービスや企業向けのドメイン。</p>
                </div>
                <div className="domain__box">
                    <span>2</span>
                    <span className="gray-do">.jp</span>
                    <p>日本を意味するドメイン</p>
                </div>
                <div className="domain__box">
                    <span>3</span>
                    <span className="gray-do">.net</span>
                    <p>ネットワークに関するサービス向けのドメイン</p>
                </div>
                <div className="domain__box">
                    <span>4</span>
                    <span className="gray-do">.org</span>
                    <p>組織や非営利団体向けのドメイン</p>
                </div>
                <div className="domain__box">
                    <span>5</span>
                    <span className="gray-do">.biz</span>
                    <p>ビジネスや企業向けのドメイン</p>
                </div>
                <div className="domain__box">
                    <span>6</span>
                    <span className="gray-do">.info</span>
                    <p>情報発信・コロミ向けのドメイン</p>
                </div>
            </div>
            <Freshchat token='e6f4c493-8ef0-42d1-836a-0007b3afd0eb' host='https://wchat.eu.freshchat.com'
                       locale='ja' tags={['mystino-fp']} />
        </div>)
    }
}
