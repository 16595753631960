import React from 'react'
import {Link} from "react-router-dom";


const BackButton = () => {
  return (
    <div className='navbar__container'>
        <div className="row">
            <Link className='button-login btn-back-home' to={`/`}>ホームに戻る</Link>
        </div>
    </div>
  )
}

export default BackButton
