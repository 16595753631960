import React from 'react'
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import InfoModal from "./InfoModal";
import RegisterSuccessModal from "./RegisterSuccessModal";
import ResetPasswordModal from "./ResetPasswordModal";
import IframeModal from "./IframeModal";


const CustomModal = ({modalActive, showRegisterModal, showLoginModal, showInfoModal, showRegisterSuccessModal, showResetPasswordModal, showIframeModal, modalUrl, ...otherProps}) => {

    return (
        <div>
            {(modalActive === 'login-modal') && <LoginModal setRegisterModal={showRegisterModal} setResetPasswordModal={showResetPasswordModal} {...otherProps} />}

            {(modalActive === 'register-modal') && <RegisterModal setLoginModal={showLoginModal} showRegisterSuccessModal={showRegisterSuccessModal} {...otherProps} />}

            {(modalActive === 'info-modal') && <InfoModal setLoginModal={showLoginModal} setRegisterModal={showRegisterModal} {...otherProps} />}

            {(modalActive === 'register-success') && <RegisterSuccessModal {...otherProps} />}

            {(modalActive === 'reset-password') && <ResetPasswordModal setRegisterModal={showRegisterModal} {...otherProps} />}

            {(modalActive === 'iframe-modal') && <IframeModal setIframeModal={showIframeModal} modalUrl={modalUrl} {...otherProps} />}
        </div>
    );
}

export default CustomModal


