import React from 'react'
import { Helmet } from 'react-helmet'
import BackButton from "../components/BackButton";
import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css'


const TermsConditions = () => (
    <div className="mb-5 terms-and-conditions">
        <Helmet>
            <title> Mystino 利用規約</title>
        </Helmet>

        <div className='gameplay__container' style={{color: 'white'}}>
            <div className='container text-center' style={{fontSize: 24}}>
                <h1>Mystino 利用規約</h1>
            </div>
        </div>

        <div className="container">
            <div className="row mb-5">
                <div className="col-lg-12 text-left ml-2">
                    <br/>
                    <br/>

                    <p>バージョン： 1.0</p>
                    <p>更新日：2020年6月2日</p>
                    <p>&nbsp;</p>
                    <ol>
                        <li><u> </u><strong><u>一般</u></strong></li>
                    </ol>
                    <p>このページは、mystino.jpの利用規約（以下利用規約）を構成し、このサイトを利用するすべての登録ユーザーは必ず本利用規約に同意する必要があります。本利用規約およびこの中で明確に言及されている文書は、当事者間の合意および理解を構成し、当社とユーザーの契約関係を規定します。www.mystino.jp（以下ウェブサイト）を利用する前に、本利用規約をしっかりお読みの上、必ず理解してください。本利用規約にご同意いただけない場合には、ウェブサイトの使用および使用の継続をおやめください。また、プライバシーポリシーに関してもよく理解しておかれることをお勧めします。</p>
                    <p>&nbsp;</p>
                    <p><u>1.1</u></p>
                    <p>このウェブサイトは、ジブラルタルの法律に基づいて登録されている、番号114021のAlcanada Media Partners Limited（以下「当社」または「Alcanada」）（登録住所：Suite 3, Second Floor, Icom House, 1/5 Irish Town, Gibraltar GX11 1AA）により運営されています。</p>
                    <p>&nbsp;</p>
                    <p><u>1.2 </u></p>
                    <p>Alcanadaは、適用される法律や規制およびその他の規制要件の順守など、さまざまな理由で本利用規約を随時変更する権利を留保します。したがって、当社はユーザーとAlcanada間の本契約を、いつでも改正できるものとします。利用規約に大幅な改正が行われた場合は、ログイン時のウェブサイト上で通知を行うものとします。改正された利用規約に拘束されることに同意しない場合、アカウントの閉鎖を希望する場合は、ユーザーがカスタマーサポートに連絡する必要があります。利用規約に変更があったかどうかを確認するためには、本利用規約の上部に記載されているバージョン番号と更新日をご参照ください。</p>
                    <p>&nbsp;</p>
                    <p><u>1.3</u></p>
                    <p><em>1.3.1</em></p>
                    <p>　本利用規約には、以下のようないくつかの追加用語が含まれています：</p>
                    <p>当社がユーザーから収集した、またはユーザーから当社に提供された個人情報の処理に関する条件を定めた当社のプライバシーポリシー</p>
                    <p><em><br /> 1.3.2</em></p>
                    <p>ユーザーは、随時改正されることがある本利用規約に含まれる条項に拘束されることを理解し、同意するものとします。</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <ol start="2">
                        <li><u> </u><strong><u>義務、必須条件および責任</u></strong></li>
                    </ol>
                    <p><u>2.1</u></p>
                    <p>本利用規約に同意しアカウント登録を行うことで、ユーザーは、次のことを表明し、保証するものとします：</p>
                    <p><em>2.1.1</em><em>　</em></p>
                    <p>アカウントは個人的に登録されたものでなくてはならず、ユーザー名およびパスワードを含むユーザーのすべてのアカウント情報、およびウェブサイトを介してユーザーのアカウントにアクセスするその他の方法に関する責任は、ユーザーが単独で負うものとします。</p>
                    <p>アカウントの安全性が損なわれた疑いがある場合は、直ちに当社に連絡し、アカウントへの不正なアクセスを防ぐための適切な措置を講じる必要があります。</p>
                    <p>&nbsp;</p>
                    <p><em>2.1.2</em></p>
                    <p>ユーザーは、ユーザー自身の能力で、他の人に代わってではなくユーザー自身のために、楽しみや娯楽の目的のみで当ウェブサイトを利用するものします。</p>
                    <p>&nbsp;</p>
                    <p><em>2.1.3</em></p>
                    <p>アカウントの登録時および存続期間中に要請される個人情報は、真実で完全かつ正確に提供するものとします。個人情報は、氏名、住所、生年月日、メールアドレス、電話番号などを含みますが、これに限定されることはありません。この義務を守り、情報が常に最新のものであることを保証するのはユーザーの責任です。情報が変更された場合、および登録フォームで提供した必須情報に変更があった場合は、当社に通知するものとします。</p>
                    <p>&nbsp;</p>
                    <p><em>2.1.4</em></p>
                    <p>Mystino.jpのサポートエージェントに対して、侮辱的もしくは節度を欠いた発言を避け、協力的な態度で接するものとします。チャットの使用は、当社のサービスおよびサービスに厳密に関連する問い合わせのみに限定されます。この条件の不履行は、いかなる状況下においても認められません。</p>
                    <p>&nbsp;</p>
                    <ol start="3">
                        <li><u> </u><strong><u>アカウント</u></strong></li>
                    </ol>
                    <p><u>3.1</u></p>
                    <p>アカウント登録過程の一環として、ウェブサイトにログインするためのユーザー名とパスワードを選択する必要があります。ログイン情報を安全に保管することは、ユーザー自身が完全に責任を負うものとします。ユーザーは、ログイン情報を決して誰にも開示してはいけません。当社は、意図的または偶発的、能動的または受動的だったに関わらず、第三者へのログイン情報の開示に起因する、第三者によるアカウントの悪用や誤用に関する責任を一切負いません。ユーザーのログイン情報を使用して行われた活動は、ユーザー自身によって実行されたものとみなされ、そのような活動から生じる責任はすべてユーザーが追うものとします。万が一、第三者がユーザーのログイン情報を認識している場合、当社に通知すること、およびログイン情報を変更することはユーザーの責任です。</p>
                    <p><u>3.2</u></p>
                    <p>ユーザーは、当ウェブサイトをハッキングしたり、いかなる方法でも当サイトのコードを変更したりしないことに同意します。また、当サイトを使用するにあたり、ユーザー自身、または第三者による試行または作用にかかわらず、ロボット、自動的、機械的、電子的またはそのほかのデバイスを使用して、当サイトでの決断を自動的に行わないことに同意するものとします。当社は、これらのデバイス、またはプレイヤーに不当な優位性を提供するように作られた外部のリソースが、当ウェブサイトで使用されていると合理的に判断された場合には、そのようなデバイスを使って行われたと思われるプレイを無効にする権利を留保します。当社は、調査の対象となるアカウントを一時閉鎖し、当社の独自の裁量でアカウントを閉鎖する場合があります。</p>
                    <p><u>3.3</u></p>
                    <p>Alcanadaは、外国の政府において重要な地位を占める人物（外国PEPs）とみなされるユーザーへのサービスの提供は行っておりません。いずれかの段階で外国PEPsと判断された場合は、ユーザーのアカウントは閉鎖されます。外国PEPsとしての判断に同意しない場合は、メールまたはチャットでご連絡ください。</p>
                    <p>&nbsp;</p>
                    <ol start="4">
                        <li><u> </u><strong><u>カジノゲーム特有のルール</u></strong></li>
                    </ol>
                    <p><u>4.1</u></p>
                    <p>このセクションに明記されている特有の規定は、ウェブサイト上の特有のタブから発見できる特有のゲームの、ルール、条件、契約条項について言及しています。そのようなゲームのルールは、当利用規約の不可欠な要素となります。</p>
                    <p>&nbsp;</p>
                    <p><u>4.2</u></p>
                    <p>ユーザーは、ゲームのゲームに関連する、いかなるルール、およびすべてのルールに従わなくてはなりません。</p>
                    <p>&nbsp;</p>
                    <ol start="5">
                        <li><u> </u><strong><u>保証と責任</u></strong></li>
                    </ol>
                    <p><u>5.1</u></p>
                    <p>Alcanadaは以下の事項を保証しません：当ウェブサイトが完璧で、エラーなく作動すること、ウェブサイトおよび当サイトで提供しているゲームに中断なくアクセスできること、ウェブサイトおよびゲームが目的に適合していること。Alcanadaは明示的または示唆的にかかわらず、このような保証は一切いたしかねます。</p>
                    <p><u>5.2</u></p>
                    <p>Alcanadaは、ユーザーの機器、インターネット接続、または第三者プロバイダーに起因する故障や問題に関して一切責任を負いません。これには、ゲームがプレイできない、または特定のゲームに関連する情報の表示または受信ができない場合も含まれます。</p>
                    <p><u>5.3</u></p>
                    <p>Alcanadaは独自の裁量で、ユーザーに事前通知することなく、当ウェブサイトおよび特定のゲームを一時的に利用不可能にする権利を留保します。当社は、こういった状況の結果としてユーザーに生じた損失について一切の責任を負いません。</p>
                    <p><u>5.4</u></p>
                    <p>Alcanadaは、いかなるダウンタイム、サーバーの中断、遅延、またはゲームプレイの技術的もしくは政治的妨害についての一切の責任を負いません。</p>
                    <p>&nbsp;</p>
                    <ol start="6">
                        <li><u> </u><strong><u>責任制限</u></strong></li>
                    </ol>
                    <p><u>6.1</u></p>
                    <p>ユーザーは、当ウェブサイトで提供されるサービスが娯楽目的のみであることを認めるものとします。ユーザーは、当サービスを使用することを要求されてはおらず、ユーザーのみの選択および裁量で当サイトのサービスに参加するものとします。結果として、ユーザーは、ユーザー自身の責任で当ウェブサイトを訪れ、ゲームに参加します。ユーザーは、当ウェブサイトおよびサービスへの関心は、職業上のものではなく個人的なものであり、個人の娯楽の目的のみでアカウントを登録したと断言するものとします。当社が提供するサービスのその他の目的の使用は、固く禁じられています。</p>
                    <p><u>6.2</u></p>
                    <p>前述の規定の一般性を失うことなく、Alcanadaおよびその取締役、従業員、パートナーは、法律で許可されている範囲で、直接的、間接的、特徴的、結果的、偶発的またはその他のかたちに関わらず、ユーザーの当ウェブサイトの使用またはゲームへの参加に関連して生じた、いかなる損失、費用、経費または損害について責任を負いません。なお、ゲームでのエラーまたは誤作動に対してAlcanadaは責任を負いません。</p>
                    <p><u>6.3</u></p>
                    <p>当ウェブサイトに表示されているすべての情報は、情報提供のみを目的として提供されており、いかなる性質の専門的なアドバイスを提供することは意図していません。Alcanadaおよびその独立したプロバイダーは、情報の誤り、不完全性、不正確さ、遅延、またはその中に含まれる情報に依存して取られた行動について、責任を負いません</p>
                    <p>&nbsp;</p>
                    <ol start="7">
                        <li><u> </u><strong><u>知的財産</u></strong></li>
                    </ol>
                    <p><u>7.1</u></p>
                    <p>ウェブサイト上のすべての知的財産権(IP権)は、Alcanadaまたは第三者のソフトウェアプロバイダーに帰属します。当ウェブサイトを利用することによって、当サイトまたはソフトウェアプロバイダーの知的財産権の所有権が、ユーザーに付与されることはありません。知的財産権には、特許、著作権、意匠権、商標、データベース権やこれらいずれかのアプリケーション、また著作者人格権、ノウハウ、企業秘密、ドメイン名、URL、商号、およびその他すべての知的財産権および産業所有権 (およびこれらに関係したライセンス) などがありますが、登録の有無、登録が可能であるかどうか、特定の国や地域、または世界の他の地域に存続するかどうかにかかわらず、これらに限定されません。<br /> </p>
                    <p><u>7.2</u></p>
                    <p>ユーザーは、適用する法律で規定された範囲内で以下のことを行うことはできません：<br /> </p>
                    <p><em>7.2.1</em></p>
                    <p>ソフトウェアおよびウェブサイトのコピー、配布、公開、リバースエンジニアリング、逆コンパイル、逆アセンブル、修正または翻訳を行ったり、ソフトウェアおよび/またはウェブサイトのソースコードから二次的著作物を作成するためにソースコードにアクセスしようとすること<br /> </p>
                    <p><em>7.2. 2</em></p>
                    <p>ソフトウェアの販売、譲渡、再許諾、移譲、配布、または賃貸<br /> </p>
                    <p><em>7.2.3</em></p>
                    <p>コンピューターネットワークなどを介して、第三者がソフトウェアを使用できるようにすること<br /> </p>
                    <p><em>7.2.4</em></p>
                    <p>物理的または電子的手段を問わず、ソフトウェアを他国に輸出すること<br /> </p>
                    <p><em>7.2.5</em></p>
                    <p>適用される法律または規制で禁止されている方法でソフトウェアを使用すること<br /> </p>
                    <p><em>7.2.6</em></p>
                    <p>IP権に害を及ぼす、またはその可能性がある行為を行うこと、また、Alcanada、その従業員、取締役、役員、およびコンサルタントのイメージや世評を損なう行為を行うこと</p>
                    <p>&nbsp;</p>
                    <p><u>7.3</u></p>
                    <p>ユーザーは、ウェブサイトまたはゲームに関連してユーザーが使用する名前や画像（ユーザー名など）が、第三者の知的財産権、プライバシー、またはその他の権利を損害したり、他者に対して不快なものでないことを保証するものとします。ユーザーは、当社のプライバシーポリシーの条件に従い、ウェブサイトまたはゲームに関連するあらゆる目的で、これらの名前および画像を全世界において、使用する許可取り消し不能にする許可、譲渡可能にする許可、著作権使用料無料にする許可、サブライセンス可能な許可を、Alcanadaに付与します。</p>
                    <p>&nbsp;</p>
                    <ol start="8">
                        <li><u> </u><strong><u>クレーム・仲裁</u></strong></li>
                    </ol>
                    <p><u>8.1</u></p>
                    <p>当ウェブサイトに関するクレームの申し立ては、ウェブサイトの手順に従ってカスタマーサポート（<a href="mailto:info@mystino.jp">info@mystino.jp</a>, 050-5534-8466）までご連絡ください。</p>
                    <p><u>8.2</u></p>
                    <p>クレームはカスタマーサポートチームにより対処され、サポートエージェントが直ちに解決できない場合にはAlcanada内でエスカレーションされます。ユーザーは、クレームの状況について合理的に通知されるものとします。当社は、可能な限り短い時間でクレームを解決するよう常に努めます。</p>
                    <p>&nbsp;</p>
                    <ol start="9">
                        <li><u> </u><strong><u>雑則</u></strong></li>
                    </ol>
                    <p><u>9.1</u></p>
                    <p>本利用規約の条項に違反した場合、または違反したと疑われる合理的な根拠がある場合、当社は、ユーザーのアカウントの開設拒否、停止、または閉鎖する権利を留保します。上記は、状況に応じて適切とみなされる、ユーザーに対する法的措置をとる権利を排除するものではありません。<br /> </p>
                    <p><u>9.2</u></p>
                    <p>本利用規約は、情報提供および利便性の目的で、多言語にて公開されていますが、英語版の本利用規約が他の言語版に優先するものとします。<br /> </p>
                    <p><u>9.3</u></p>
                    <p>当社は、当社の合理的な管理が及ばない事件、出来事、または原因に起因する当社の本利用規約に基づく義務の履行遅延または不履行について、本利用規約に違反したとされず責任を負わないものとします。</p>
                    <p>&nbsp;</p>
                    <p><u>9.4</u></p>
                    <p>本利用規約のいずれかの規定が、違法または法的強制力がないと判断された場合、そのような規定は本利用規約から分離されます。その他のすべての規定は引き続き有効であり、この分離による影響は一切受けません。</p>
                    <p>&nbsp;</p>
                    <p><u>9.5</u></p>
                    <p>本利用規約はジブラルタルの法律に準拠しています。ユーザーは、本利用規約に起因する、または関連する異議、論争、クレーム、また本利用規約内の違反、終了、無効は、ジブラルタル裁判所の専属管轄権に服従することに同意するものとします。</p>
                </div>
            </div>
            <BackButton/>
            <Freshchat token='e6f4c493-8ef0-42d1-836a-0007b3afd0eb' host='https://wchat.eu.freshchat.com'
                       locale='ja' tags={['mystino-fp']} />
        </div>
    </div>
)

export default TermsConditions
