import React, { useEffect } from "react";
import logo from "../assets/imgs/logo_new_mystino.svg";
import bunny from "../assets/imgs/bunny.png";
import { Link } from "react-router-dom";
import DomainsContainer from "../components/DomainsContainer";

export default class NewNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavActive: false,
    };
  }

  toggleNav = (e) => {
    if (e) {
      e.preventDefault();
    }
    //update z-index so that the logo from the main page is not visible
    const logo = document.querySelector('.main-logo-wrp');
    if(logo){
      logo.style.zIndex = !this.state.isNavActive ?1: 3;
    }
    this.setState({ isNavActive: !this.state.isNavActive });
  };

  render() {
    return (
      <div>
        <div className="main-nav-header">
          <span
            className="menu-icon"
            style={{ fontSize: 30, cursor: "pointer" }}
            onClick={this.toggleNav}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 25.3374H29"
                stroke="#E32862"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3 16.004H20.6667"
                stroke="#E32862"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3 6.67078H29"
                stroke="#E32862"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <Link to={`/`} className="main-logo-wrp">
            <img src={logo} alt="logo" />
          </Link>
          {/*<span className="bunny">*/}
          {/*    <img src={bunny} alt="bunny" />*/}
          {/*</span>*/}
        </div>
        <DomainsContainer />
        <div
          className={
            "menu-backdrop " + (this.state.isNavActive ? "active" : "")
          }
          onClick={this.toggleNav}
        >
          <a href="" className="closebtn" onClick={this.toggleNav}>
            ×
          </a>
        </div>
        <div
          ref="snav"
          className={"overlay " + (this.state.isNavActive ? "active" : "")}
        >
          <div className="sidenav-container" onClick={this.toggleNav}>
            <div className="nav-header">
              <Link className="logo-wrp" to={`/`}>
                <img className="logo" style={{zIndex: 3}} src={logo} alt="logo" />
              </Link>
              {/*<span className="bunny">*/}
              {/*    <img src={bunny} alt="bunny" />*/}
              {/*</span>*/}
            </div>
            {this.props.currentUser ? (
              <div className="item_links loggedIn">
                <Link to={`/media-exposure`}>メディア</Link>
                <Link to={`/domains`}>ドメインについて</Link>
                <Link to={`/responsible-gaming`}>責任あるゲーム</Link>
                <Link to={`/how-to-play`}>ゲームの遊び方</Link>
                <Link
                  className="borderBottom"
                  onClick={() => {
                    this.props.signOut();
                  }}
                >
                  ログアウト
                </Link>
              </div>
            ) : (
              <div className="item_links">
                <div className="props-links borderBottom">
                  {this.props.registerButton}
                </div>
                <div className="props-links">{this.props.loginButton}</div>
                <div className="props-links">
                  <Link to={`/media-exposure`}>メディア</Link>
                  <Link to={`/responsible-gaming`}>責任あるゲーム</Link>
                  <Link to={`/how-to-play`}>ゲームの遊び方</Link>
                </div>
                <div className="mt-20">
                  <Link to={`/domains`}>ドメインについて</Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
