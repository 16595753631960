import React, {Component} from 'react'
import {Button, Modal, Form} from 'react-bootstrap'
import {FaTwitter} from 'react-icons/fa'
import UserIcon from '../../assets/imgs/user.png'

import {signInWithTwitter} from "../../firebase/firebase.utils";

class InfoModal extends Component {

    onSubmit = async e => {
        e.preventDefault();
    };

    onSubmitTwitter = () => {
        signInWithTwitter();
        this.props.onHide();
    }

    render() {
        const {setLoginModal, setRegisterModal, show, onHide} = this.props;

        return (
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-register"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {/*<img className='user-icon' src={UserIcon} alt=''/>*/}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>新規登録 / ログイン</h4>
                    <Form onSubmit={this.onSubmit} noValidate>
                        <Form.Group>
                            <div>新規登録またはログインをすると、すべてのゲームにアクセスすることができます。</div>
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox" className="text-center">
                            <Button variant="primary" type="button" className="btnReg"
                                    onClick={setRegisterModal}>
                                新規登録
                            </Button>
                            <Form.Text className="text-muted f-10">
                                既にアカウントをお持ちですか？ <a href={'#'} onClick={setLoginModal}>今すぐログイン</a>
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox" className="text-center mobile">
                            <Form.Text className="text-muted">
                                または下記の方法でログイン
                            </Form.Text>
                            <Button variant="primary" className="btn-twit"
                                    onClick={this.onSubmitTwitter}>
                                <FaTwitter/>
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default InfoModal


