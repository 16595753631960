import React from "react";

export default function BubbleChatJack({ text, classNames }) {
  return (
    <div className={`jack-chat-container ${classNames}`}>
      <div className="bubble-container-purple">
        <div className="bubble-purple">
          <img
            className="bubble-purple-tail"
            src={require("../assets/imgs/bubble/Tail_Purple.png")}
          />
          <p className="bubble-text">{text}</p>
        </div>
        <img
          className="bubble-jack-icon"
          src={require("../assets/imgs/bubble/Jack_Avatar_x2.png")}
        />
      </div>
    </div>
  );
}
