import React from "react";

export default function BubbleTextMysti({ text, classNames }) {
  return (
    <div className={`mysti-chat-container ${classNames}`}>
      <div className="bubble-container-pink">
        <img
          className="bubble-mysti-icon"
          src={require("../assets/imgs/bubble/Mysti_Avatar_x2.png")}
        />

        <div className="bubble-pink">
          <img
            className="bubble-pink-tail"
            src={require("../assets/imgs/bubble/Tail_Pink.png")}
          />
          <p className="bubble-text">{text}</p>
        </div>
      </div>
    </div>
  );
}
