import React from 'react';

const DomainsContainer = () => {
    return (
        <div className="domains-container">
            <p>こちらは無料版です</p>
            <div className="input-domains">
                <span>mystino</span>
                <p className="domain-extension">
                    <span>.jp</span>
                    <span>.jp</span>
                </p>

                <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="26" height="19" rx="1.5" fill="#FBFBFA" stroke="#EDEEEF"/>
                    <circle cx="13" cy="10" r="4" fill="#DA1E28"/>
                </svg>
                <span className="spacer-flag"/>
                <span className="money-icon">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_16_527)">
                                <path
                                    d="M16.6 8.25231H14.43C14.2474 8.24689 14.0656 8.27814 13.8949 8.3443C13.7242 8.41045 13.568 8.51021 13.4352 8.63786C13.3024 8.76551 13.1956 8.91856 13.1209 9.08826C13.0462 9.25796 13.0051 9.44098 13 9.62686C13.0046 9.90713 13.0933 10.1793 13.2542 10.4067C13.4151 10.6342 13.6404 10.8062 13.9 10.8996L16.1 11.7549C16.3595 11.8483 16.5849 12.0202 16.7457 12.2477C16.9066 12.4752 16.9953 12.7473 17 13.0276C16.9948 13.2135 16.9537 13.3965 16.879 13.5662C16.8043 13.7359 16.6975 13.8889 16.5647 14.0166C16.4319 14.1442 16.2757 14.244 16.105 14.3101C15.9343 14.3763 15.7525 14.4076 15.57 14.4021H13.4"
                                    stroke="#24A249" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15 8.25236V7" stroke="#24A249" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path d="M15 15.6546V14.4022" stroke="#24A249" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path
                                    d="M18.75 18.75H24.75C25.1478 18.75 25.5293 18.592 25.8106 18.3107C26.0919 18.0294 26.25 17.6478 26.25 17.25V5.25C26.25 4.85218 26.0919 4.47064 25.8106 4.18934C25.5293 3.90804 25.1478 3.75 24.75 3.75H5.24995C4.85213 3.75 4.4706 3.90804 4.18929 4.18934C3.90799 4.47064 3.74995 4.85218 3.74995 5.25V11.76"
                                    fill="#24A249" fillOpacity="0.15"/>
                                <path
                                    d="M18.75 18.75H24.75C25.1478 18.75 25.5293 18.592 25.8106 18.3107C26.0919 18.0294 26.25 17.6478 26.25 17.25V5.25C26.25 4.85218 26.0919 4.47064 25.8106 4.18934C25.5293 3.90804 25.1478 3.75 24.75 3.75H5.24995C4.85213 3.75 4.4706 3.90804 4.18929 4.18934C3.90799 4.47064 3.74995 4.85218 3.74995 5.25V11.76"
                                    stroke="#24A249" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M7.49995 7.13003C7.54937 7.12869 7.59856 7.13727 7.64462 7.15527C7.69067 7.17326 7.73264 7.2003 7.76807 7.2348C7.80349 7.26929 7.83164 7.31053 7.85086 7.35608C7.87008 7.40164 7.87997 7.45058 7.87995 7.50003C7.87995 7.60081 7.83991 7.69746 7.76865 7.76873C7.69739 7.83999 7.60073 7.88003 7.49995 7.88003C7.45051 7.88004 7.40156 7.87015 7.35601 7.85094C7.31045 7.83172 7.26921 7.80357 7.23472 7.76814C7.20023 7.73272 7.17319 7.69074 7.15519 7.64469C7.1372 7.59864 7.12861 7.54945 7.12995 7.50003C7.12857 7.45106 7.1372 7.40233 7.1553 7.35682C7.17341 7.3113 7.20061 7.26996 7.23525 7.23532C7.26988 7.20069 7.31123 7.17348 7.35674 7.15538C7.40225 7.13727 7.45099 7.12865 7.49995 7.13003Z"
                                    fill="#24A249" stroke="#24A249" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                                <path
                                    d="M22.4999 14.62C22.5494 14.62 22.5983 14.6299 22.6439 14.6491C22.6894 14.6683 22.7307 14.6965 22.7652 14.7319C22.7997 14.7673 22.8267 14.8093 22.8447 14.8553C22.8627 14.9014 22.8713 14.9506 22.8699 15C22.8713 15.049 22.8627 15.0977 22.8446 15.1432C22.8265 15.1887 22.7993 15.2301 22.7647 15.2647C22.73 15.2993 22.6887 15.3265 22.6432 15.3446C22.5976 15.3627 22.5489 15.3714 22.4999 15.37C22.4505 15.3713 22.4013 15.3627 22.3553 15.3448C22.3092 15.3268 22.2673 15.2997 22.2318 15.2652C22.1964 15.2307 22.1683 15.1895 22.149 15.1439C22.1298 15.0984 22.1199 15.0494 22.1199 15C22.1199 14.8992 22.16 14.8026 22.2312 14.7313C22.3025 14.66 22.3992 14.62 22.4999 14.62"
                                    stroke="#24A249" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M3.74995 16.25V23.25C3.74995 24.91 6.43995 26.25 9.74995 26.25C13.06 26.25 15.75 24.91 15.75 23.25V16.25"
                                    fill="#FEF6E2"/>
                                <path
                                    d="M3.74995 16.25V23.25C3.74995 24.91 6.43995 26.25 9.74995 26.25C13.06 26.25 15.75 24.91 15.75 23.25V16.25"
                                    stroke="#FAC13C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M15.75 19.75C15.75 21.41 13.06 22.75 9.74995 22.75C6.43995 22.75 3.74995 21.41 3.74995 19.75"
                                    stroke="#FAC13C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M9.74995 19.25C13.0637 19.25 15.75 17.9069 15.75 16.25C15.75 14.5931 13.0637 13.25 9.74995 13.25C6.43625 13.25 3.74995 14.5931 3.74995 16.25C3.74995 17.9069 6.43625 19.25 9.74995 19.25Z"
                                    fill="#FEF6E2" stroke="#FAC13C" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                            </g>
                            <line x1="26.8276" y1="1.41421" x2="1.41422" y2="26.8276" stroke="#2D384D" strokeWidth="2"
                                  strokeLinecap="round"/>
                            <defs>
                                <clipPath id="clip0_16_527">
                                    <rect width="24" height="24" fill="white" transform="translate(2.99995 3)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        </span>
            </div>
        </div>
    )
}

export default DomainsContainer;