import React, {Component} from 'react'
import {Button, Modal, Form} from 'react-bootstrap'

class IframeModal extends Component {

    render() {
        const {show, onHide, modalUrl} = this.props;

        return (
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-iframe"
                closeButton
            >
                <Modal.Header closeButton></Modal.Header>
                <iframe width="660" height="500" src={modalUrl ?? localStorage.getItem('urlIframe')}
                        title="YouTube video mystino"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </Modal>
        );
    }
}

export default IframeModal


