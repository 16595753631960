import React from "react";
import { Helmet } from "react-helmet";
import { Freshchat } from "reactjs-freshchat";
import "reactjs-freshchat/dist/index.css";
import BubbleChatJack from "../components/BubbleChatJack";
import BubbleChatMysti from "../components/BubbleChatMysti";
import HowToPlayVideos from "../components/HowToPlayVideos";
import dataHowToPlay from "../data/howToPlay";

export default function HowToPlay() {
  return (
    <div className="mb-5 terms-and-conditions">
      <Helmet>
        <title>Mystino</title>
      </Helmet>

      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-12 text-left ml-2">
            <h1 className="htp-title">オンラインカジノゲームの遊び方</h1>
            <BubbleChatMysti
              text={
                "カジノゲーム初心者だと、どのゲームをどうやって遊べばいいか迷っちゃうよね。"
              }
            />
            <BubbleChatJack
              text={
                "そうだね。でもそんなに難しいことはないよ。基本ルールさえ理解できれば、あとは応用するだけさ！"
              }
            />
            <BubbleChatMysti
              classNames="htp-bubble-mysti"
              text={
                "確かに！このページではミスティがカジノゲームの遊び方を解説している動画をたくさん用意しているので、ぜひみんなにも参考にしてほしいな！"
              }
            />
            <h2 className="htp-subtitle">オンラインカジノゲームの遊び方</h2>
            <p className="htp-paragraph">
              オンラインカジノのゲームには、大きく分けて２種類あります。1つ目は、リールを回転させたりシンボルを連鎖させたりして配当を獲得するスロット。2つ目は、本物のディーラーとリアルタイムでカードゲームやルーレットなどがプレイできるライブカジノです。
            </p>
            <p>&nbsp;</p>
              <HowToPlayVideos
                  title={"スロットの遊び方"}
                  bigDescription={
                      "リールを回してペイライン上にシンボルが揃うと配当が発生する、というのがスタンダードなスロットの遊び方です。最近では、パズルゲームのように、シンボルをつなげて連鎖させることで配当が発生する、「カスケード形式」または「落ちもの系」と呼ばれるタイプのスロットゲームも多く登場してきています。ゲームごとに特有のボーナスフィーチャーなどがあるものの、仕組み自体に大きな違いはないため、基本的なルールさえ理解できれば、幅広いスロットゲームを楽しむことができます。スロットの基本解説を紹介している動画、またミスティーノでも特に人気のスロットの遊び方解説動画をぜひご参考にしてください。"
                  }
                  data={dataHowToPlay.howToPlayFirstColumn}
              />
              <p>&nbsp;</p>
              <HowToPlayVideos
                  title={"ライブカジノの遊び方"}
                  bigDescription={
                      "ライブカジノでは、バカラやポーカーなどのトランプを使うゲームや、ルーレット、またライブショー形式のゲームを遊ぶことができます。ライブカジノの最大の特徴は、本物のディーラーと、ネットを通じてリアルタイムでゲームをプレイすることができる点です。本場のカジノさながらの臨場感を味わいながら遊ぶことができます。ライブカジノの基本的な遊び方について紹介している動画、そして人気のゲームごとに遊び方を紹介している動画をご用意しておりますので、ぜひご覧ください。 "
                  }
                  data={dataHowToPlay.howToPlaySecondColumn}
              />
            <h2 className="htp-subtitle">オンラインカジノゲーム基本用語</h2>
            <p className="htp-paragraph">
              &#x2022; スピン：1スピン＝スロットゲームの1回転。
              <br /> &#x2022;
              フリースピン：自分が入金したお金を消費することなく、特定のスロットゲームを無料でプレイすることができるスピン。
              <br /> &#x2022;
              ワイルドシンボル：スロットゲームで、他のすべての絵柄の代わりとなるシンボルのこと。ワイルドシンボルが表示されると、ペイラインが成立する確率も高くなります。
              <br /> &#x2022; RTP：英語のReturn To Player
              (リターン・トゥー・プレーヤー)の略語で、賭け金に対してどれくらいの割合の金額が戻ってくるかの割合を表します（数多くのベットに対しての平均値）。
            </p>
          </div>
        </div>
      </div>
      <Freshchat
        token="e6f4c493-8ef0-42d1-836a-0007b3afd0eb"
        host="https://wchat.eu.freshchat.com"
        locale="ja"
        tags={["mystino-fp"]}
      />
    </div>
  );
}
