import React from 'react'
import { Modal, Form} from 'react-bootstrap'
import UserIcon from '../../assets/imgs/user.png'

const RegisterSuccessModal = ({show, onHide}) => {
        return (
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-register"
                id="register-success"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {/*<img className='user-icon' src={UserIcon} alt=''/>*/}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>ログインに成功しました</h4>
                    <Form noValidate>
                        <Form.Group>
                            <div className='text-center'>ご登録ありがとうございます。すべてのゲームを無料でプレイして頂くことができます。</div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
}

export default RegisterSuccessModal


