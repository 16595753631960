import React from "react";
import { Helmet } from "react-helmet";
import { Freshchat } from "reactjs-freshchat";
import "reactjs-freshchat/dist/index.css";
import BubbleChatJack from "../components/BubbleChatJack";
import BubbleChatMysti from "../components/BubbleChatMysti";

const ResponsibleGaming = () => (
  <div className="mb-5 terms-and-conditions">
    <Helmet>
      <title>Mystino</title>
    </Helmet>

    <div className="container">
      <div className="row mb-5">
        <div className="col-lg-12 text-left ml-2">
          <h1 className="rg-title">責任あるゲーム</h1>
          <BubbleChatMysti
            text={"もしかして最近ゲーム遊びすぎかな？ちょっと心配かも。"}
          />
          <BubbleChatJack
            classNames="rg-bubble-jack"
            text={
              "節度を持ってゲームを楽しめるよう、ミスティーノではいくつかのサポートを提供しているよ。ぜひチェックしてみてね！"
            }
          />
          <h2 className="rg-subtitle">予防措置</h2>
          <p className="rg-paragraph">
            オンラインカジノゲームは日々の暮らしに楽しみをもたらすエンターテイメントとして、世界中の多くの大人に愛されています。
            このようにカジノゲームは楽しく刺激があるものですが、私たちはプレイヤーの皆様が健全に、コントロールを保ちながらゲームを楽しんでいただけるようにサポートすることを目指しております。
            <br />
            カジノゲームを愛する方々が健全なプレイを楽しんで頂けるよう、様々なツールとサポートが存在しています。
          </p>
          <p>&nbsp;</p>
          <p className="rg-paragraph">
            ご自身のギャンブル習慣が気になる方は、ユーザーがゲームサイトにアクセスするのを防ぐことができるサードパーティソフトウェアツールなどをインストールできます。
            <br /> &#x2022; GamBlock
            <br /> &#x2022; NetNanny
            <br /> &#x2022; Betfilter
          </p>

          <h2 style={{ marginTop: "25px" }} className="rg-subtitle">
            自己診断テスト
          </h2>
          <p className="rg-paragraph">
            カジノゲームをプレイするにあたり、以下のいずれかに該当することはありますか？
            <br/> &#x2022; ギャンブルに時間を費やしすぎと周りの人から言われる。
            <br/> &#x2022; 他のことに使うべきお金でギャンブルをすることがある。
            <br/> &#x2022;
            他のことをしなければならないのに、ギャンブルに時間を割いてしまう。
            <br/> &#x2022; ギャンブルをするためにお金を借りることがある。
            <br/> &#x2022; ギャンブルをする機会がないと落ち着かない。
            上記のようにご自身のギャンブル習慣に不安な点がある方は、自己診断テストを受けることをお勧めします。
            <br/>
            自己診断テスト: <a
              className="link-in-paragraph color-pink"
              href="https://gamcare.gamtest.se/"
              target="_blank"
          >https://gamcare.gamtest.se/</a>
          </p>
          <p>&nbsp;</p>
          <p className="rg-paragraph">
            このテストは、危険なギャンブル行為の初期兆候を発見するために設計されています。テストを完了すると、あなたの答えに基づいた詳細なフィードバックと、利用可能なその他のリソースへのリンクが表示されます。
            <br/>
            このテストは匿名で行われ、また提供された情報は安全な方法で記録され、自己診断以外の目的で使用されることはありませんのでご安心ください。
          </p>

          <h2 style={{ marginTop: "25px" }} className="rg-subtitle">
            相談が必要な場合
          </h2>
          <p className="rg-paragraph">
            カジノゲームに没頭するあまり日常生活に支障をきたしていると感じられる場合は、一度カスタマーサポートにご連絡ください。お話をお伺いし、どのようなサポートをさせていただけるかご案内をいたします。
            <br />
            また、ミスティーノでは以下の専門機関への相談も推奨しております。
            <br /> &#x2022;{" "}
            <a
              className="link-in-paragraph color-pink"
              href="https://gamblingtherapy.org/"
              target="_blank"
            >
              gamblingtherapy.org
            </a>
            <br /> &#x2022;{" "}
            <a
              className="link-in-paragraph color-pink"
              href="https://scga.jp/"
              target="_blank"
            >
              scga.jp
            </a>
            <br /> &#x2022;{" "}
            <a
              className="link-in-paragraph color-pink"
              href="https://gamcare.org.uk/"
              target="_blank"
            >
              gamcare.org.uk
            </a>
          </p>
        </div>
      </div>
    </div>
    <Freshchat
      token="e6f4c493-8ef0-42d1-836a-0007b3afd0eb"
      host="https://wchat.eu.freshchat.com"
      locale="ja"
      tags={["mystino-fp"]}
    />
  </div>
);

export default ResponsibleGaming;
